import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { FavouriteChapter } from './favourite-chapter.model';
import { SnackbarService } from '../../components/snackbar/snackbar.service';
import { SnackBarType } from '../../components/snackbar/snackbar-type.enum';
import { StateService } from '../state/state.service';
import { VersesListDialogService } from '../verses-list-dialog.service';

@Injectable()
export class LeftPanelService {
  private favouriteChaptersSubject: BehaviorSubject<FavouriteChapter[]>;
  private lastChaptersSubject: BehaviorSubject<FavouriteChapter[]>;

  get favouriteChapters$(): Observable<FavouriteChapter[]> { return this.favouriteChaptersSubject.asObservable(); }
  get lastChapters$(): Observable<FavouriteChapter[]> { return this.lastChaptersSubject.asObservable(); }

  get favouriteChapters(): FavouriteChapter[] {
    if (this.versesListDialogService.isDefaultList()) {
      return StateService.getArray('favourite-chapters');
    } else {
      return this.versesListDialogService.getCurrentListChapters();
    }
  }

  get lastChapters(): FavouriteChapter[] { return StateService.getArray('last-chapters'); }

  constructor(
    private snackbarService: SnackbarService,
    private versesListDialogService: VersesListDialogService
  ) {
    this.favouriteChaptersSubject = new BehaviorSubject<FavouriteChapter[]>(this.favouriteChapters);
    this.lastChaptersSubject = new BehaviorSubject<FavouriteChapter[]>(this.favouriteChapters);
  }

  setFavouriteChapter(value: FavouriteChapter): void {
    if (this.versesListDialogService.isDefaultList()) {
      this.setDefaultFavouriteChapter(value);
    } else {
      this.versesListDialogService.setListChapter(value);
      this.favouriteChaptersSubject.next(this.versesListDialogService.getCurrentListChapters());
    }
  }

  setFavouriteChapters(value: FavouriteChapter[]): void {
    if (this.versesListDialogService.isDefaultList()) {
      this.favouriteChaptersSubject.next(StateService.setArray('favourite-chapters', value));
    } else {
      this.versesListDialogService.setList(this.versesListDialogService.currentList, value);
    }
  }

  setLastChapter(value: FavouriteChapter): void {
    const lastChapters = this.lastChapters;

    const chapterIndex = lastChapters.findIndex(chapter =>
      chapter.bible === value.bible &&
      chapter.book === value.book &&
      chapter.chapter === value.chapter &&
      chapter.verse === value.verse);

    if (chapterIndex >= 0) {
      lastChapters.splice(chapterIndex, 1);
    } else if (lastChapters.length > 4) {
      lastChapters.pop();
    }
    const updatedChapters: FavouriteChapter[] = [value, ...lastChapters];
    this.lastChaptersSubject.next(StateService.setArray<FavouriteChapter>('last-chapters', updatedChapters));
  }

  removeFavouriteChapter(value: FavouriteChapter): void {
    if (this.versesListDialogService.isDefaultList()) {
      this.removeDefaultFavouriteChapter(value);
    } else {
      this.versesListDialogService.removeListChapter(value);
      this.favouriteChaptersSubject.next(this.versesListDialogService.getCurrentListChapters());
    }
  }

  private setDefaultFavouriteChapter(value: FavouriteChapter): void {
    if (this.favouriteChapters.length >= 50) {
      this.snackbarService.open('Zapisano maksymalną liczbę 50 fragmentów.', SnackBarType.ERROR, 2000);
    } else {
      this.snackbarService.open(`Dodano ${!!value.verse ? 'werset' : 'rozdział'} do ulubionych.`, SnackBarType.ADD, 2000);
      this.favouriteChaptersSubject.next(StateService.setArrayElement('favourite-chapters', value));
    }
  }

  private removeDefaultFavouriteChapter(value: FavouriteChapter): void {
    this.snackbarService.open(`Usunięto ${!!value.verse ? 'werset' : 'rozdział'} z ulubionych.`, SnackBarType.REMOVE, 2000)
    this.favouriteChaptersSubject.next(StateService.removeArrayElement('favourite-chapters', (compare) => {
      return compare.bible === value.bible
        && compare.book === value.book
        && compare.chapter === value.chapter
        && compare.verse === value.verse
    }));
  }
}
