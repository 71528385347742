import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-available-translations',
  templateUrl: './bible-available-translations.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterModule
]
})
export class BibleAvailableTranslationsComponent {
  @Output() protected readonly navToTranslationsPage$ = new EventEmitter<void>();
}
