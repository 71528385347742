import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import {
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';

import { IconButtonComponent } from '../../../../../components/icon-button/icon-button.component';
import { MediaDirectivesModule } from '../../../../../directives/media/media-directives.module';
import { SharedPipesModule } from '../../../../../pipes/shared-pipes.module';
import { StrongStateService } from '../../../../../services/strong/strong-state.service';
import { StrongSettingsResult } from './strong-settings.model';

@Component({
  selector: 'app-strong-settings',
  templateUrl: './strong-settings.component.html',
  styleUrls: ['./strong-settings.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    IconButtonComponent,
    MatIconModule,
    MatDialogModule,
    MediaDirectivesModule,
    SharedPipesModule,
  ],
})
export class StrongSettingsComponent implements OnInit, OnDestroy {
  protected _compactMode: boolean;
  protected _fullScreen: boolean;
  protected _showGreek: boolean;
  protected _showStrong: boolean;
  protected _showTransliteration: boolean;

  set compactMode(value: boolean) {
    this.stateService.setCompactMode(value);
    this._compactMode = value;
  }

  set fullScreen(value: boolean) {
    this.stateService.setFullScreen(value);
    this._fullScreen = value;
  }

  set showGreek(value: boolean) {
    this.stateService.setShowGreek(value);
    this._showGreek = value;
  }

  set showStrong(value: boolean) {
    this.stateService.setShowStrong(value);
    this._showStrong = value;
  }

  set showTransliteration(value: boolean) {
    this.stateService.setShowTransliteration(value);
    this._showTransliteration = value;
  }

  constructor(
    private stateService: StrongStateService,
    protected dialogRef: MatDialogRef<StrongSettingsComponent, StrongSettingsResult>,
  ) {}

  ngOnInit(): void {
    this._compactMode = this.stateService.compactMode;
    this._fullScreen = this.stateService.fullScreen;
    this._showGreek = this.stateService.showGreek;
    this._showStrong = this.stateService.showStrong;
    this._showTransliteration = this.stateService.showTransliteration;
  }

  ngOnDestroy(): void {
    this.dialogRef.close({
      compactMode: this._compactMode,
      fullScreen: this._fullScreen,
      showGreek: this._showGreek,
      showStrong: this._showStrong,
      showTransliteration: this._showTransliteration,
    });
  }
}
