import { BibliaInfoBook } from './biblia-info-book';

export const BIBLE_ALTERNATIVE_CODES: Map<string, string> = new Map([
  ['mt', BibliaInfoBook.EWANGELIA_MATEUSZA],
  ['mk', BibliaInfoBook.EWANGELIA_MARKA],
  ['lk', BibliaInfoBook.EWANGELIA_LUKASZA],
  ['łuk', BibliaInfoBook.EWANGELIA_LUKASZA],
  ['łk', BibliaInfoBook.EWANGELIA_LUKASZA],
  ['j', BibliaInfoBook.EWANGELIA_JANA],
  ['jn', BibliaInfoBook.EWANGELIA_JANA],
  ['rzym', BibliaInfoBook.LIST_DO_RZYMIAN],
  ['ga', BibliaInfoBook.LIST_DO_GALACJAN],
  ['efez', BibliaInfoBook.LIST_DO_EFEZJAN],
  ['fl', BibliaInfoBook.LIST_DO_FILIPIAN],
  ['flp', BibliaInfoBook.LIST_DO_FILIPIAN],
  ['1tm', BibliaInfoBook.PIERWSZY_LIST_DO_TYMOTEUSZA],
  ['2tm', BibliaInfoBook.DRUGI_LIST_DO_TYMOTEUSZA],
  ['tt', BibliaInfoBook.LIST_DO_TYTUSA],
  ['filem', BibliaInfoBook.LIST_DO_FILEMONA],
  ['hbr', BibliaInfoBook.LIST_DO_HEBRAJCZYKOW],
  ['jk', BibliaInfoBook.LIST_JAKUBA],
  ['judy', BibliaInfoBook.LIST_JUDY],
  ['ap', BibliaInfoBook.OBJAWIENIE_JANA],
  ['1m', BibliaInfoBook.RODZAJU],
  ['1mojz', BibliaInfoBook.RODZAJU],
  ['1mojż', BibliaInfoBook.RODZAJU],
  ['rodz', BibliaInfoBook.RODZAJU],
  ['2m', BibliaInfoBook.WYJSCIA],
  ['2mojz', BibliaInfoBook.WYJSCIA],
  ['2mojż', BibliaInfoBook.WYJSCIA],
  ['wyj', BibliaInfoBook.WYJSCIA],
  ['3m', BibliaInfoBook.KAPLANSKA],
  ['3mojz', BibliaInfoBook.KAPLANSKA],
  ['3mojż', BibliaInfoBook.KAPLANSKA],
  ['kapl', BibliaInfoBook.KAPLANSKA],
  ['kapł', BibliaInfoBook.KAPLANSKA],
  ['kpł', BibliaInfoBook.KAPLANSKA],
  ['4m', BibliaInfoBook.LICZB],
  ['4mojz', BibliaInfoBook.LICZB],
  ['4mojż', BibliaInfoBook.LICZB],
  ['liczb', BibliaInfoBook.LICZB],
  ['5m', BibliaInfoBook.POWTORZONEGO_PRAWA],
  ['5mojz', BibliaInfoBook.POWTORZONEGO_PRAWA],
  ['5mojż', BibliaInfoBook.POWTORZONEGO_PRAWA],
  ['sedz', BibliaInfoBook.SEDZIOW],
  ['ru', BibliaInfoBook.RUT],
  ['rut', BibliaInfoBook.RUT],
  ['1sa', BibliaInfoBook.PIERWSZA_SAMUELA],
  ['1sam', BibliaInfoBook.PIERWSZA_SAMUELA],
  ['2sa', BibliaInfoBook.DRUGA_SAMUELA],
  ['2sam', BibliaInfoBook.DRUGA_SAMUELA],
  ['1krol', BibliaInfoBook.PIERWSZA_KROLEWSKA],
  ['1król', BibliaInfoBook.PIERWSZA_KROLEWSKA],
  ['2krol', BibliaInfoBook.DRUGA_KROLEWSKA],
  ['2król', BibliaInfoBook.DRUGA_KROLEWSKA],
  ['1kron', BibliaInfoBook.PIERWSZA_KRONIK],
  ['2kron', BibliaInfoBook.DRUGA_KRONIK],
  ['ezdr', BibliaInfoBook.EZDRASZA],
  ['neh', BibliaInfoBook.NEHEMIASZA],
  ['job', BibliaInfoBook.HIOBA],
  ['hiob', BibliaInfoBook.HIOBA],
  ['psalm', BibliaInfoBook.PSALMY],
  ['pr', BibliaInfoBook.PRZYPOWIESCI],
  ['przy', BibliaInfoBook.PRZYPOWIESCI],
  ['ekl', BibliaInfoBook.KAZNODZIEI],
  ['jer', BibliaInfoBook.JEREMIASZA],
  ['la', BibliaInfoBook.LAMENTACJI],
  ['lam', BibliaInfoBook.LAMENTACJI],
  ['tr', BibliaInfoBook.LAMENTACJI],
  ['eze', BibliaInfoBook.EZECHIELA],
  ['da', BibliaInfoBook.DANIELA],
  ['dan', BibliaInfoBook.DANIELA],
  ['joel', BibliaInfoBook.JOELA],
  ['amos', BibliaInfoBook.AMOSA],
  ['mich', BibliaInfoBook.MICHEASZA],
  ['nach', BibliaInfoBook.NAHUMA],
  ['hab', BibliaInfoBook.HABAKUKA],
  ['sof', BibliaInfoBook.SOFONIASZA],
  ['zach', BibliaInfoBook.ZACHARIASZA],
  ['mal', BibliaInfoBook.MALACHIASZA],
]);
