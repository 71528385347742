<div mat-dialog-title>
  <h1>Listy wersetów</h1>

  <app-icon-button label="zamknij okno" icon="close" class="icon-large close" (click$)="dialogRef.close()"></app-icon-button>
</div>

<div mat-dialog-content>
  <mat-radio-group aria-labelledby="wybierz listę wersetów" class="radio-group" [(ngModel)]="selectedList">
    @for (listName of lists; track listName; let i = $index) {
      <mat-radio-button [value]="listName" (change)="valueChange($event.value)">
        {{ listName }} ({{ listsCount.get(listName) || 0 }})
      </mat-radio-button>
    }
  </mat-radio-group>

  <div class="input-container">
    <mat-form-field class="form-field">
      <mat-label>Dodaj nową listę</mat-label>
      <input matInput placeholder="nazwa" [(ngModel)]="inputName">
    </mat-form-field>

    <button (click)="add()" class="btn" aria-label="dodaj nową listę" matTooltip="dodaj nową listę">
      <mat-icon svgIcon="icons:add_circle_outline"></mat-icon>
    </button>

    @if (selectedList !== 'Ulubione') {
      <button class="btn" (click)="remove()" aria-label="usuń wybraną listę" matTooltip="usuń wybraną listę">
        <mat-icon svgIcon="icons:delete"></mat-icon>
      </button>
    }
  </div>
</div>
