import { AsyncPipe, KeyValuePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, Inject, inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
  MatOptgroup,
  MatOption
} from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';

import { map, startWith, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BIBLE_ALTERNATIVE_CODES } from '../../../../../services/biblia-info/bible-alt-codes';
import { BibleBook, BOOKS_NAMES_1, BOOKS_NAMES_2 } from '../../../../../services/biblia-info/available-books';
import { BibliaInfoBook } from '../../../../../services/biblia-info/biblia-info-book';
import { BOOKS_CHAPTERS } from '../../../../../services/biblia-info/books-chapters';
import { IsNumberPipe } from '../../../../../pipes/types/is-number.pipe';
import { PlEnPipe } from '../../../../../pipes/pl-en.pipe';
import { SubComponent } from '../../../../../components/utils/sub/sub.component';

export type BibleQueryBook = [BibliaInfoBook, BibleBook];

export interface BibleQueryData {
  onlyNT: boolean;
}

export interface BibleQueryResult {
  book: BibliaInfoBook;
  chapter: number;
}

@Component({
  templateUrl: './bible-search-query-dialog.component.html',
  styleUrls: ['./bible-search-query-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    IsNumberPipe,
    KeyValuePipe,
    MatAutocomplete,
    MatAutocompleteTrigger,
    MatDialogContent,
    MatFormField,
    MatInput,
    MatLabel,
    MatOptgroup,
    MatOption,
    ReactiveFormsModule,
  ],
})
export class BibleSearchQueryDialogComponent extends SubComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger, { static: false }) autocomplete: MatAutocompleteTrigger;

  protected readonly form = new FormGroup({
    name: new FormControl(''),
  });

  private books: BibleQueryBook[];
  protected bookChapters: Map<BibliaInfoBook, number>;
  protected filteredBooks: Observable<(BibleQueryBook | number)[]>;

  protected selectedBook: string;
  protected maxBookChapters: number;
  private selectedChapter: number;
  private submitEnabled = false;

  protected readonly breakIndexes = [4,16,17,21,26,38,42,43,52,57,64];

  private readonly data = inject<BibleQueryData>(MAT_DIALOG_DATA);
  private readonly dialogRef = inject(MatDialogRef<BibleSearchQueryDialogComponent, BibleQueryResult>);

  ngOnInit() {
    this.books = Array.from(new Map(this.data.onlyNT ? [...BOOKS_NAMES_2] : [...BOOKS_NAMES_1, ...BOOKS_NAMES_2]));
    this.bookChapters = BOOKS_CHAPTERS;
    this.observeInputValueChanges()
  }

  @HostListener('window:keydown.enter')
  handleKeyboardEvent() {
    if (!this.submitEnabled) {
      if (this.selectedBook) {
        this.validateChapterBasedOnInput();
      }
      return;
    }
    this.closeDialog();
  }

  private closeDialog(): void {
    this.dialogRef.close(this.selectedChapter ? {
      book: this.getBookCodeByName(this.selectedBook),
      chapter: this.selectedChapter,
    } : {
      book: this.getBookCodeByName(this.selectedBook)
    });
  }

  private observeInputValueChanges(): void {
    this.filteredBooks = this.form.controls.name.valueChanges.pipe(
      startWith(''),
      tap(() => {
        this.submitEnabled = false;
        if (this.selectedBook && !this.getRawValue(this.form.controls.name.value).startsWith(this.getRawValue(this.selectedBook))) {
          this.selectedBook = null;
        }
      }),
      map(value => this.filterBooks(value || '')),
    );
  }

  private filterBooks(value: string): (BibleQueryBook | number)[] {
    if (this.selectedBook) {
      const code = this.getBookCodeByName(this.selectedBook);
      return this.getArrayOfNumbers(this.bookChapters.get(code));
    }
    if (BOOKS_CHAPTERS.has(value as BibliaInfoBook) && this.books.find(book => book[0] === value)) {
      return [this.books.find(book => book[0] === value)];
    }
    if (BIBLE_ALTERNATIVE_CODES.has(value)) {
      const code = BIBLE_ALTERNATIVE_CODES.get(value);
      const book = this.books.find(book => book[0] === code);
      if (book) {
        return [book];
      }
    }
    return this.books.filter(book => this.getRawValue(book[1].name).includes(this.getRawValue(value)));
  }

  private getBookCodeByName(name: string): BibliaInfoBook {
    return this.books.find(book => name === book[1].name)[0];
  }

  protected optionSelected($event: MatAutocompleteSelectedEvent): void {
    if (!this.selectedBook) {
      this.selectedBook = $event.option.value;
      this.form.setValue({ name: this.selectedBook });
      this.maxBookChapters = BOOKS_CHAPTERS.get(this.getBookCodeByName(this.selectedBook));
      setTimeout(() => {
        this.autocomplete.openPanel();
        this.submitEnabled = true;
      }, 200);
    } else {
      const chapterFromInput = +$event.option.value.match(/\d+/)[0];
      this.maxBookChapters = BOOKS_CHAPTERS.get(this.getBookCodeByName(this.selectedBook));
      this.selectedChapter = chapterFromInput <= this.maxBookChapters ? chapterFromInput : 1;
      setTimeout(() => {
        this.submitEnabled = true;
        this.closeDialog();
      }, 200);
    }
  }

  private getArrayOfNumbers(length: number): number[] {
    return Array.from({ length }, (_, i) => i + 1);
  }

  private getRawValue(value: string): string {
    return PlEnPipe.replacePolishSigns(
      value.toString().toLowerCase().replace(/\s+/g, '')
    );
  }

  private validateChapterBasedOnInput(): void {
    const value = +this.form.controls.name.value.match(/\d+/)?.at(0);

    if (value && Number.isInteger(value)) {
      this.maxBookChapters = BOOKS_CHAPTERS.get(this.getBookCodeByName(this.selectedBook));
      this.selectedChapter = value <= this.maxBookChapters ? value : 1;
      this.form.setValue({ name: `${this.selectedBook} ${this.selectedChapter}` });
      setTimeout(() => {
        this.submitEnabled = true;
        this.closeDialog();
      }, 200);
    }
  }
}
