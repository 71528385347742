import { Pipe, PipeTransform } from '@angular/core';

import { WordUbg } from '../../../../../services/strong/strong.model';

@Pipe({
  name: 'strongDescription',
  standalone: true,
})
export class StrongDescriptionPipe implements PipeTransform {
  transform(word: WordUbg): number {
    if (word.tr) {
      return 1;
    }
    if (word.na) {
      return 2;
    }
    if (word.with) {
      return 3;
    }
    if (Array.isArray(word?.n)) {
      return 4;
    }
    if (!word.t) {
      return 5;
    }
    if (word.t && !word.n && !word.tr && !word.na) {
      return 6;
    }
    return 0;
  }
}
