<div mat-dialog-title>
  <h1>
    <mat-icon svgIcon="icons:settings"></mat-icon>
    Wybór przekładów
  </h1>

  <app-icon-button label="zamknij okno" icon="close" class="icon-large close" (click$)="dialogRef.close()"></app-icon-button>
</div>

<div mat-dialog-content>
  @for (item of ['pl', 'en', 'la', 'gr']; track item) {
    <p class="separator">{{ bibleLanguageNames.get(item) }}</p>
    @for (translation of translationsPerLang.get(item); track translation.abbreviation) {
      <mat-checkbox
        [checked]="this.data.selected.includes(translation.abbreviation)"
                  [disabled]="this.data.selected.length < 2 && this.data.selected.includes(translation.abbreviation)
                  || translation.abbreviation === this.data.current || this.data.compareCurrent?.includes(translation.abbreviation)"
        (change)="change(translation.abbreviation)">
        {{ translation.name }}
      </mat-checkbox>
    }
  }

  <div class="button-container">
    <app-button text="Zapisz ustawienia" (click$)="save()"></app-button>
  </div>
</div>
