<form [formGroup]="form" class="form center">
  <mat-form-field appearance="fill" class="field-book">
    <mat-label>Księga</mat-label>
    <mat-select formControlName="book" (selectionChange)="bookChange()">
      @for (book of books; track book; let i = $index) {
        <mat-option [value]="book" [class.break]="[3,4,13,18,25].includes(i)">
          {{ book | bookName }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" class="field-chapter">
    <mat-label>Rozdział</mat-label>
    <mat-select formControlName="chapter" (selectionChange)="clearFragment()">
      @for (chapter of chapters.get(form.get('book').value) | array; track chapter) {
        <mat-option [value]="chapter">{{ chapter }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" class="field-verse">
    <mat-label>Werset</mat-label>
    <mat-select formControlName="verse" (selectionChange)="verseChange$.emit($event.value)">
      @for (verse of versesNumber | array; track verse) {
        <mat-option [value]="verse">{{ verse }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  <app-strong-actions
    [hidePrevButton]="form.get('chapter').value === 1"
    [hideNextButton]="chapters.get(form.get('book').value) === form.get('chapter').value"
    [chapter]="form.get('chapter').value"
    [isLoaded]="versesNumber > 0"
    [actionsAbsolute]="actionsAbsolute"
    [actionsAbsolutePositionInPx]="actionsAbsolutePositionInPx"
    (chapterChange$)="chapterChange($event)"
    (openBibleBookDialog$)="openBibleBookDialog()"
    (openSettings$)="openSettings()"
    (openLegend$)="openLegend()"
  ></app-strong-actions>
</form>

