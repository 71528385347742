import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BibleQueryService {
  private disableQuery: boolean;
  private _disableQuery$ = new Subject<boolean>();

  get disableQuery$(): Observable<boolean> {
    return this._disableQuery$.asObservable();
  }

  disable(): void {
    this.disableQuery = true;
    this._disableQuery$.next(this.disableQuery)
  }

  enable(): void {
    this.disableQuery = false;
    this._disableQuery$.next(this.disableQuery)
  }
}
