import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';

import { DisableBibleQueryComponent } from '../../../../../components/utils/bible-dialog/disable-bible-query.component';
import { FavouriteChapter } from '../../../../../services/left-panel/favourite-chapter.model';
import { IconButtonComponent } from '../../../../../components/icon-button/icon-button.component';
import { QuestionResult } from '../bottom-sheet/copy-chapter-dialog/left-panel-copy-chapter-dialog.component';
import { StateService } from '../../../../../services/state/state.service';
import { VersesListDialogService } from '../../../../../services/verses-list-dialog.service';
import { VersesRemoveListDialogComponent } from './verses-remove-list-dialog/verses-remove-list-dialog.component';

@Component({
  selector: 'app-verses-list-dialog',
  templateUrl: './verses-list-dialog.component.html',
  styleUrls: ['./verses-list-dialog.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    IconButtonComponent,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatTooltipModule,
    VersesRemoveListDialogComponent,
  ],
})
export class VersesListDialogComponent extends DisableBibleQueryComponent implements OnInit {
  static defaultListName = 'Ulubione';

  lists: string[] = [VersesListDialogComponent.defaultListName];
  listsCount: Map<string, number>;
  selectedList = VersesListDialogComponent.defaultListName;
  inputName: string;

  private cdr = inject(ChangeDetectorRef);
  private dialog = inject(MatDialog);
  protected dialogRef = inject(MatDialogRef<VersesListDialogComponent, void>);
  private versesListDialogService = inject(VersesListDialogService);

  private get favouriteChaptersLength(): number {
    if (this.versesListDialogService.isDefaultList()) {
      return StateService.getArray('favourite-chapters')?.length;
    } else {
      return this.versesListDialogService.getCurrentListChapters()?.length;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initLists();
    this.initListsCount();
    this.initCurrentList();
  }

  remove(): void {
    if (this.favouriteChaptersLength > 0) {
      this.dialog.open<VersesRemoveListDialogComponent, null, QuestionResult>(VersesRemoveListDialogComponent)
        .afterClosed().subscribe(res => {
          if (res === QuestionResult.YES) {
            this.removeList();
          }
        }
      );
    } else {
      this.removeList();
    }
  }

  add(): void {
    if (this.inputName && !this.lists.includes(this.inputName)) {
      this.lists.push(this.inputName);
      this.versesListDialogService.setList(this.inputName, [], true);
    }
  }

  valueChange(listName: string): void {
    this.versesListDialogService.setCurrentList(listName);
  }

  private removeList(): void {
    const index = this.lists.findIndex(list => list === this.selectedList);

    if (index !== 0 && index !== -1) {
      this.lists.splice(index, 1);
      this.versesListDialogService.removeList(this.selectedList);
      this.selectedList = VersesListDialogComponent.defaultListName;
      this.versesListDialogService.setCurrentList(VersesListDialogComponent.defaultListName);
      this.cdr.markForCheck();
    }
  }

  private initLists(): void {
    this.lists.push(...Object.keys(this.versesListDialogService.versesList));
  }

  private initCurrentList(): void {
    const currentList = this.versesListDialogService.currentList;
    if (typeof currentList !== 'string') {
      return;
    }
    if (Object.keys(this.versesListDialogService.versesList).includes(this.versesListDialogService.currentList)) {
      this.selectedList = this.versesListDialogService.currentList;
    }
  }

  private initListsCount(): void {
    const listsValues: Map<string, FavouriteChapter[]> = new Map([
      [VersesListDialogComponent.defaultListName, StateService.getArray('favourite-chapters')],
      ...new Map(Object.entries(this.versesListDialogService.versesList))
    ]);
    this.listsCount = new Map([...listsValues].map(([key, value]) => [key, value.length]));
  }
}
