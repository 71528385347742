import { ChangeDetectionStrategy, Component, HostBinding, inject, Input } from '@angular/core';
import { ViewportScroller } from '@angular/common';

import { ChapterButtonComponent, ChapterChange } from '../chapter-button.component';

@Component({
  selector: 'app-prev-chapter',
  templateUrl: './prev-chapter.component.html',
  styleUrls: ['./prev-chapter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrevChapterComponent extends ChapterButtonComponent {
  @HostBinding('class.hide') @Input({ required: true }) protected hide = false;

  private viewportScroller = inject(ViewportScroller);

  protected onClick($event: MouseEvent): void {
    this.viewportScroller.scrollToPosition([0, 0]);
    if ($event.button === 0) {
      this.emitChange({ change: ChapterChange.PREV });
    } else if ($event.button === 1) {
      this.emitChange({ change: ChapterChange.PREV, openInBlankPage: true });
    }
  }
}
