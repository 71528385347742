import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { ButtonComponent } from '../../../../../../components/button/button.component';

@Component({
  selector: 'app-verses-remove-list-dialog',
  templateUrl: './verses-remove-list-dialog.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ButtonComponent,
    MatDialogModule,
  ],
})
export class VersesRemoveListDialogComponent {
  constructor(protected dialogRef: MatDialogRef<VersesRemoveListDialogComponent, number>,) {}
}
