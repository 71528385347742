import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';

import {
  BibleBookInfoDialogComponent,
  BibleBookInfoDialogData,
  BibleBookInfoDialogResult
} from '../../bible/bible-book-info-dialog/bible-book-info-dialog.component';
import { BibleQueryParams } from '../../bible/bible.model';
import { BibliaInfoBook } from '../../../../services/biblia-info/biblia-info-book';
import { ChapterChange } from '../../bible/chapter-button/chapter-button.component';
import { SharedPipesModule } from '../../../../pipes/shared-pipes.module';
import { StrongScrollService } from '../strong-scroll.service';
import { StrongActionsComponent } from './actions/strong-actions.component';
import { StrongForm } from '../strong.model';
import { StrongLegendComponent } from './strong-legend/strong-legend.component';
import { StrongSettingsComponent } from './strong-settings/strong-settings.component';
import { StrongSettingsResult } from './strong-settings/strong-settings.model';
import { StrongStorage } from './strong-storage';
import { SubComponent } from '../../../../components/utils/sub/sub.component';

@Component({
  selector: 'app-strong-filter',
  templateUrl: './strong-filter.component.html',
  styleUrls: ['./strong-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    SharedPipesModule,
    StrongActionsComponent
]
})
export class StrongFilterComponent extends SubComponent implements OnInit {
  @Output() protected readonly formChange$ = new EventEmitter<string>();
  @Output() protected readonly compactMode$ = new EventEmitter<boolean>();
  @Output() protected readonly fullScreen$ = new EventEmitter<boolean>();
  @Output() protected readonly showGreek$ = new EventEmitter<boolean>();
  @Output() protected readonly showStrong$ = new EventEmitter<boolean>();
  @Output() protected readonly showTransliteration$ = new EventEmitter<boolean>();
  @Output() protected readonly verseChange$ = new EventEmitter<number>();

  @Input({ required: true }) readonly form: FormGroup<StrongForm>;
  @Input({ required: true }) readonly versesNumber: number;

  @Input({ required: true }) protected actionsAbsolutePositionInPx: number;
  @Input({ required: true }) protected actionsAbsolute = false;

  static readonly books: BibliaInfoBook[] = [
    BibliaInfoBook.EWANGELIA_MATEUSZA,
    BibliaInfoBook.EWANGELIA_MARKA,
    BibliaInfoBook.EWANGELIA_LUKASZA,
    BibliaInfoBook.EWANGELIA_JANA,
    BibliaInfoBook.DZIEJE_APOSTOLSKIE,
    BibliaInfoBook.LIST_DO_RZYMIAN,
    BibliaInfoBook.PIERWSZY_LIST_DO_KORYNTIAN,
    BibliaInfoBook.DRUGI_LIST_DO_KORYNTIAN,
    BibliaInfoBook.LIST_DO_GALACJAN,
    BibliaInfoBook.LIST_DO_EFEZJAN,
    BibliaInfoBook.LIST_DO_FILIPIAN,
    BibliaInfoBook.LIST_DO_KOLOSAN,
    BibliaInfoBook.PIERWSZY_LIST_DO_TESALONICZAN,
    BibliaInfoBook.DRUGI_LIST_DO_TESALONICZAN,
    BibliaInfoBook.PIERWSZY_LIST_DO_TYMOTEUSZA,
    BibliaInfoBook.DRUGI_LIST_DO_TYMOTEUSZA,
    BibliaInfoBook.LIST_DO_TYTUSA,
    BibliaInfoBook.LIST_DO_FILEMONA,
    BibliaInfoBook.LIST_DO_HEBRAJCZYKOW,
    BibliaInfoBook.LIST_JAKUBA,
    BibliaInfoBook.PIERWSZY_LIST_PIOTRA,
    BibliaInfoBook.DRUGI_LIST_PIOTRA,
    BibliaInfoBook.PIERWSZY_LIST_JANA,
    BibliaInfoBook.DRUGI_LIST_JANA,
    BibliaInfoBook.TRZECI_LIST_JANA,
    BibliaInfoBook.LIST_JUDY,
    BibliaInfoBook.OBJAWIENIE_JANA,
  ];

  static readonly chapters: Map<BibliaInfoBook, number> = new Map([
    [BibliaInfoBook.EWANGELIA_MATEUSZA, 28],
    [BibliaInfoBook.EWANGELIA_MARKA, 16],
    [BibliaInfoBook.EWANGELIA_LUKASZA, 24],
    [BibliaInfoBook.EWANGELIA_JANA, 21],
    [BibliaInfoBook.DZIEJE_APOSTOLSKIE, 28],
    [BibliaInfoBook.LIST_DO_RZYMIAN, 16],
    [BibliaInfoBook.PIERWSZY_LIST_DO_KORYNTIAN, 16],
    [BibliaInfoBook.DRUGI_LIST_DO_KORYNTIAN, 13],
    [BibliaInfoBook.LIST_DO_GALACJAN, 6],
    [BibliaInfoBook.LIST_DO_EFEZJAN, 6],
    [BibliaInfoBook.LIST_DO_FILIPIAN, 4],
    [BibliaInfoBook.LIST_DO_KOLOSAN, 4],
    [BibliaInfoBook.PIERWSZY_LIST_DO_TESALONICZAN, 5],
    [BibliaInfoBook.DRUGI_LIST_DO_TESALONICZAN, 3],
    [BibliaInfoBook.PIERWSZY_LIST_DO_TYMOTEUSZA, 6],
    [BibliaInfoBook.DRUGI_LIST_DO_TYMOTEUSZA, 4],
    [BibliaInfoBook.LIST_DO_TYTUSA, 3],
    [BibliaInfoBook.LIST_DO_FILEMONA, 1],
    [BibliaInfoBook.LIST_DO_HEBRAJCZYKOW, 13],
    [BibliaInfoBook.LIST_JAKUBA, 5],
    [BibliaInfoBook.PIERWSZY_LIST_PIOTRA, 5],
    [BibliaInfoBook.DRUGI_LIST_PIOTRA, 3],
    [BibliaInfoBook.PIERWSZY_LIST_JANA, 5],
    [BibliaInfoBook.DRUGI_LIST_JANA, 1],
    [BibliaInfoBook.TRZECI_LIST_JANA, 1],
    [BibliaInfoBook.LIST_JUDY, 1],
    [BibliaInfoBook.OBJAWIENIE_JANA, 22],
  ]);

  get books(): BibliaInfoBook[] {
    return StrongFilterComponent.books;
  }

  get chapters(): Map<BibliaInfoBook, number> {
    return StrongFilterComponent.chapters;
  }
  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private strongScrollService: StrongScrollService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.observeQueryParams();
    this.observeFragmentChange();
  }

  protected bookChange(): void {
    this.form.get('verse').setValue(null);
    this.validateBook();
    this.clearFragment();
  }

  protected emitFormChange(): void {
    this.saveFormInStorage();
    this.updateQueryParams();
    this.formChange$.emit(this.activatedRoute.snapshot.fragment);
  }

  protected chapterChange(change: ChapterChange): void {
    this.form.get('chapter').setValue(
      this.form.get('chapter').value + (change === ChapterChange.NEXT ? 1 : -1)
    );
    this.clearFragment();
  }

  protected openBibleBookDialog(): void {
    const openBibleBookDialogRef = this.dialog.open<
      BibleBookInfoDialogComponent,
      BibleBookInfoDialogData,
      BibleBookInfoDialogResult
    >(BibleBookInfoDialogComponent, {
      panelClass: 'reading', minWidth: '80vw', data: {
        book: this.form.get('book').value, chapter: this.form.get('chapter').value, onlyGreek: true
      }
    });

    openBibleBookDialogRef.afterClosed().subscribe((result: BibleBookInfoDialogResult) => {
      if (!result) {
        return;
      }
      this.form.get('book').setValue(result.book);
      this.form.get('chapter').setValue(result.chapter);
      this.emitFormChange();
    });
  }

  updateQueryParams(verse?: string): void {
    this.router.navigate(
      ['../ubg'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          ksiega: this.form.get('book').value,
          rozdzial: this.form.get('chapter').value,
        },
        fragment: verse || this.activatedRoute.snapshot.fragment,
        queryParamsHandling: 'merge',
      });
  }

  protected clearFragment(): void {
    this.form.get('verse').setValue(null);
    this.router.navigate(
      ['../ubg'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          ksiega: this.form.get('book').value,
          rozdzial: this.form.get('chapter').value,
        },
        fragment: null,
        queryParamsHandling: 'merge',
      });
  }

  protected openSettings(): void {
    const openSettingsDialogRef: MatDialogRef<StrongSettingsComponent, StrongSettingsResult> = this.dialog.open<StrongSettingsComponent, void>(StrongSettingsComponent, {
      panelClass: 'reading', minWidth: '30vw'
    });
    openSettingsDialogRef.afterClosed().subscribe(result => {
      this.compactMode$.emit(result.compactMode);
      this.fullScreen$.emit(result.fullScreen);
      this.showGreek$.emit(result.showGreek);
      this.showStrong$.emit(result.showStrong);
      this.showTransliteration$.emit(result.showTransliteration);
    })
  }

  protected openLegend(): void {
    this.dialog.open<StrongLegendComponent, void>(StrongLegendComponent, {
      panelClass: 'reading', minWidth: '80vw'
    });
  }

  private validateBook(): void {
    const book = this.form.get('book').value;
    const availableChapters = this.chapters.get(book);
    // const availableChapters = BOOKS_CHAPTERS.get(book);
    if (this.form.get('chapter').value > availableChapters) {
      this.form.get('chapter').setValue(1);
    }
  }

  private static isStrongStorage(object: any): object is StrongStorage {
    return 'book' in object && 'chapter' in object;
  }

  private isStrongStorageValid(strongStorage: StrongStorage): boolean {
    const availableChapters = this.chapters.get(strongStorage.book);
    if (!availableChapters) {
      return false;
    }
    const chapter = +strongStorage.chapter;
    return Number.isInteger(chapter) && chapter > 0 && chapter <= availableChapters;
  }

  private getFormFromStorage(): StrongStorage {
    const strongFilter: StrongStorage = JSON.parse(localStorage.getItem('strong-filter'));
    if (!strongFilter || !StrongFilterComponent.isStrongStorage(strongFilter)) {
      return null;
    }
    if (this.isStrongStorageValid(strongFilter)) {
      return {
        book: strongFilter.book,
        chapter: strongFilter.chapter,
      }
    }
  }

  private saveFormInStorage(): void {
    localStorage.setItem('strong-filter', JSON.stringify(this.form.value));
  }

  private observeQueryParams(): void {
    this.subscription.add(this.activatedRoute.queryParams.subscribe((params: BibleQueryParams) => {
      const { ksiega, rozdzial } = params;
      const formFromStorage = this.getFormFromStorage();

      this.setBookFromParams(ksiega, formFromStorage?.book);
      this.setChapterFromParams(+rozdzial, formFromStorage?.chapter);

      this.emitFormChange();
    }));
  }

  private setBookFromParams(ksiega: BibliaInfoBook, ksiegaFromStorage: BibliaInfoBook): void {
    const availableChapters = this.chapters.get(ksiega?.toLowerCase() as BibliaInfoBook);

    if (availableChapters) {
      this.form.get('book').setValue(ksiega.toLowerCase() as BibliaInfoBook);
    } else if (ksiegaFromStorage) {
      this.form.get('book').setValue(ksiegaFromStorage);
    }
  }

  private setChapterFromParams(rozdzial: number, rozdzialFromStorage: number): void {
    const availableChapters = this.chapters.get(this.form.get('book').value);

    if (rozdzial > 0 && rozdzial <= availableChapters) {
      return this.form.get('chapter').setValue(rozdzial);
    }
    if (rozdzialFromStorage <= availableChapters) {
      return this.form.get('chapter').setValue(rozdzialFromStorage);
    }
  }

  private observeFragmentChange(): void {
    this.subscription.add(this.strongScrollService.scrollToAnchor$.subscribe(anchor => {
      this.updateQueryParams(anchor + '');
    }));
  }
}
