<div mat-dialog-title>
  <h1>
    <mat-icon svgIcon="icons:info"></mat-icon>
    <ng-container *onlyDesktopTabletLandscape>{{ data.chapterItem.bible.name }}</ng-container>
    <ng-container *onlyPortrait>{{ data.chapterItem.bible.abbreviation | uppercase }}</ng-container>
  </h1>

  <app-icon-button label="zamknij okno" icon="close" class="icon-large close" (click$)="dialogRef.close()"></app-icon-button>
</div>

<div mat-dialog-content>
  @if (data.description && data.chapterItem) {
    <p>
      {{ data.description }}
    </p>
  }

  @if (data.chapterItem) {
    <app-available-translations
      (navToTranslationsPage$)="dialogRef.close()"
    ></app-available-translations>
  }
</div>
