import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export enum QuestionResult { NO, YES}

@Component({
  templateUrl: './left-panel-copy-chapter-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftPanelCopyChapterDialogComponent {
  protected readonly dialogRef = inject(MatDialogRef<LeftPanelCopyChapterDialogComponent, number>);
}
