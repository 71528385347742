import { Pipe, PipeTransform } from '@angular/core';

import { StrongBasic, WordUbg } from '../../../services/strong/strong.model';

@Pipe({
  name: 'strongNumber',
  standalone: true,
})
export class StrongNumberPipe implements PipeTransform {
  transform(word: WordUbg): string {
    if (word.n?.toString().includes(',')) {
      return word.n.toString().replaceAll(',', ', ');
    }
    return (word.n || word.tr || word.na || '-').toString();
  }
}

@Pipe({
  name: 'strongRoot',
  standalone: true,
})
export class StrongRootPipe implements PipeTransform {
  transform(word: WordUbg, numbersMap: Map<number | number[], StrongBasic>): string {
    if (word.n?.toString().includes(',')) {
      return word.n.toString().split(',').map(el => numbersMap.get(+el)?.root).join(', ');
    }
    return numbersMap.get(word.n || word.tr || word.na)?.root || '-';
  }
}

@Pipe({
  name: 'strongTransliteration',
  standalone: true,
})
export class StrongTransliterationPipe implements PipeTransform {
  transform(word: WordUbg, numbersMap: Map<number | number[], StrongBasic>): string {
    if (word.n?.toString().includes(',')) {
      return word.n.toString().split(',').map(el => numbersMap.get(+el)?.transliteration).join(', ');
    }
    return numbersMap.get(word.n || word.tr || word.na)?.transliteration || '-';
  }
}
