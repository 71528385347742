import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BibliaInfoBook } from '../../../../services/biblia-info/biblia-info-book';
import { BibliaInfoChapterModel } from '../../../../services/biblia-info/biblia-info.model';
import { BibliaInfoCode } from '../../../../services/biblia-info/biblia-info-code';
import { BibleContentBottomSheetResultType } from '../content/bottom-sheet/bible-content-bottom-sheet.component';
import { CompareVerseBottomSheetComponent, CompareVerseBottomSheetResult } from './bottom-sheet/compare-verse-bottom-sheet.component';
import { FavouriteChapter } from '../../../../services/left-panel/favourite-chapter.model';
import { LeftPanelService } from '../../../../services/left-panel/left-panel.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SnackbarService } from '../../../../components/snackbar/snackbar.service';
import { SnackBarType } from '../../../../components/snackbar/snackbar-type.enum';
import { StateService } from '../../../../services/state/state.service';

@Component({
  templateUrl: './compare-verse-dialog.component.html',
  styleUrls: ['./compare-verse-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LeftPanelService],
})
export class CompareVerseDialogComponent implements OnInit {
  static readonly defaultAbbreviationOrder = [
    'bt', 'bw', 'be', 'br', 'esp', 'bg',
    'ng', 'ug', 'ubg', 'bb', 'bp', 'jw',
    'bm', 'ns', 'bl', 'sz', 'eib', 'snp',
    'tnp', 'kjv', 'web', 'ylt', 'vul', 'gr'
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: BibliaInfoChapterModel[],
              public dialogRef: MatDialogRef<CompareVerseDialogComponent, void>,
              private bottomSheet: MatBottomSheet,
              private cdr: ChangeDetectorRef,
              private clipboard: Clipboard,
              private leftPanelService: LeftPanelService,
              private snackbarService: SnackbarService) {}

  ngOnInit(): void {
    this.initDataOrderFromStorage();
  }

  openBottomSheet(model: BibliaInfoChapterModel): void {
    const favouriteChapter = this.getFavouriteChapter(model.bible.abbreviation as BibliaInfoCode);

    this.bottomSheet.open(CompareVerseBottomSheetComponent, {
      data: {
        item: model,
        isInStorage: this.findFavouriteChapter(favouriteChapter)
      }
    }).afterDismissed().subscribe((result: CompareVerseBottomSheetResult) => {
      switch (result?.type) {
        case BibleContentBottomSheetResultType.COPY_VERSE: this.copyVerse(model.verses[0].text); break;
        case BibleContentBottomSheetResultType.ADD_VERSE: this.addVerse(model.bible.abbreviation as BibliaInfoCode); break;
        case BibleContentBottomSheetResultType.REMOVE_VERSE: this.removeVerse(model.bible.abbreviation as BibliaInfoCode); break;
      }
    });
  }

  drop(event: CdkDragDrop<BibliaInfoChapterModel[]>): void {
    moveItemInArray(this.data, event.previousIndex, event.currentIndex);
    const abbreviationOrder = this.data.map(item => item.bible.abbreviation);

    if (abbreviationOrder.length !== CompareVerseDialogComponent.defaultAbbreviationOrder.length) {
      const newOrder = CompareVerseDialogComponent.swapAbbrevInArray(abbreviationOrder);
      StateService.setArray<string>('compare-verse-abbreviation-order', newOrder);
    } else {
      StateService.setArray<string>('compare-verse-abbreviation-order', abbreviationOrder);
    }
  }

  private static swapAbbrevInArray(array: string[]): string[] {
    const storageOrder = StateService.getArray<string>('compare-verse-abbreviation-order');

    for (let i = 0; i < array.length - 1; i++) {
      storageOrder[storageOrder.findIndex(el => el === array[i])] = array[i + 1];
      storageOrder[storageOrder.findIndex(el => el === array[i + 1])] = array[i];
    }
    return storageOrder;
  }

  private initDataOrderFromStorage(): void {
    let order = StateService.getArray('compare-verse-abbreviation-order');
    if (order.length === 0) {
      StateService.setArray<string>('compare-verse-abbreviation-order', CompareVerseDialogComponent.defaultAbbreviationOrder);
      order = CompareVerseDialogComponent.defaultAbbreviationOrder;
    }
    this.data.sort((a, b) => {
      return order.findIndex(item => item === a.bible.abbreviation) < order.findIndex(item => item === b.bible.abbreviation) ? -1 : 1
    });
  }

  private copyVerse(verse: string): void {
    this.clipboard.copy(verse);
    this.snackbarService.open('Skopiowano werset', SnackBarType.COPY, 2000);
  }

  private addVerse(bibleAbbreviation: BibliaInfoCode): void {
    this.leftPanelService.setFavouriteChapter(this.getFavouriteChapter(bibleAbbreviation));
    this.cdr.markForCheck();
  }

  private removeVerse(bibleAbbreviation: BibliaInfoCode): void {
    this.leftPanelService.removeFavouriteChapter(this.getFavouriteChapter(bibleAbbreviation));
    this.cdr.markForCheck();
  }

  private findFavouriteChapter(compare: FavouriteChapter): boolean {
    return !!this.leftPanelService.favouriteChapters.find(value => {
      return compare.bible === value.bible
        && compare.book === value.book
        && compare.chapter === value.chapter
        && compare.verse === value.verse
    })
  }

  private getFavouriteChapter(bibleAbbreviation: BibliaInfoCode): FavouriteChapter {
    return {
      bible: bibleAbbreviation,
      book: this.data[0].book.abbreviation as BibliaInfoBook,
      chapter: this.data[0].chapter,
      verse: +this.data[0].verses[0].verse,
    };
  }
}
