<p class="meta">Najpopularniejsze przekłady Biblii online, narzędzia wyszukiwania tekstu, porównywanie tłumaczeń, ulubione wersety dostępne w Apologetyka Biblijna.</p>

<article class="page-wrapper">
  <section class="section-container">
    <app-bible-filter [bibleGroup]="bibleGroup"
                      [bookGroup]="bookGroup"
                      [books]="books"
                      [chapters]="chapters"
                      [form]="form"
                      [fullScreen]="fullScreen"
                      (bibleSelectionChange$)="bibleSelectionChange($event)"
                      (bookSelectionChange$)="bookSelectionChange($event)"
                      (chapterSelectionChange$)="chapterSelectionChange($event)"
                      (bibleCompareSelectionChange$)="bibleCompareSelectionChange($event)"
                      (versesInNewLine$)="versesInNewLine = $event"
                      (copyVerses$)="copyVerses()"
                      (openSelectTranslationsDialog$)="openSelectTranslationsDialog()"
                      (changeFontSize$)="fontSize = $event"
                      (maxCompareColumns$)="onMaxCompareColumnsChange($event)"
    ></app-bible-filter>
  </section>

  <aside *onlyDesktop>
    <app-bible-left-panel [collapse]="collapseAsideFilter"
                          [bookGroup]="bookGroup"
                          [favouriteChapters]="favouriteChapters"
                          [books]="books"
                          [lastChapters]="lastChapters"
                          [hidden]="hideLeftPanel"
                          [over1700px]="over1700px"
                          [sideNavOpen]="isSideNavOpen"
                          (selectChapter$)="leftPanelSelectChapter($event)"
                          (selectVerse$)="leftPanelSelectChapter($event, true)"
                          (copyChapter$)="leftPanelCopyChapter($event)"
                          (copyChapterWithNumbers$)="leftPanelCopyChapterWithNumbers($event)"
                          (compareVerse$)="compareVerse($event)">
    </app-bible-left-panel>
  </aside>

  <section class="section-container">
    <app-bible-content (chapterChange$)="chapterButtonChange($event)"
                       (compareVerse$)="compareVerse($event)"
                       (favouriteChapterChange$)="favouriteChapterChange($event)"
                       (openToolsPanel$)="openToolsDialog()"
                       (openBibleBookDialog$)="openBibleBookDialog()"
                       (openSelectTranslationsDialog$)="openSelectTranslationsDialog()"
                       (fullScreen$)="fullScreenMode($event)"
                       [versesInNewLine]="versesInNewLine"
                       [fullScreen]="fullScreen"
                       [chapterItem]="chapterItem"
                       [compareItems]="compareChapterItems"
                       [versesUbg]="versesUbg"
                       [fontSize]="fontSize"
                       [description]="description"
                       [hideNextButton]="hideNextChapterButton"
                       [hidePrevButton]="hidePrevChapterButton"
                       [isFavouriteChapter]="isFavouriteChapter"
                       [isLoading]="isLoading"
                       [maxCompareColumns]="maxCompareColumns"
    ></app-bible-content>
  </section>

  <aside *onlyDesktop>
    <app-bible-right-panel [bibleGroup]="bibleGroup"
                           [bookGroup]="bookGroup"
                           [chapters]="chapters"
                           [compareChapterItems]="compareChapterItems"
                           [form]="form"
                           [isFavouriteChapter]="isFavouriteChapter"
                           [over1700px]="over1700px"
                           [forceCollapse]="collapseAsideFilter"
                           [hidePrevButton]="hidePrevChapterButton"
                           [hideNextButton]="hideNextChapterButton"
                           (bibleSelectionChange$)="bibleSelectionChange($event)"
                           [stopAnimationOnHideButtonChange]="stopAnimationOnHideButtonChange"
                           (bookSelectionChange$)="bookSelectionChange($event)"
                           (chapterSelectionChange$)="chapterSelectionChange($event)"
                           (bibleCompareSelectionChange$)="bibleCompareSelectionChange($event, true)"
    ></app-bible-right-panel>
  </aside>
</article>

<app-bible-search-query *onlyDesktop (query$)="bibleSearchQuery($event)"></app-bible-search-query>
