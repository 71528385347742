import { StrongBasic, VerseUbg } from '../../../../../services/strong/strong.model';

export const strongLegendExample: VerseUbg[] = [
  {
    text: [
      {
        t: 'A',
        n: 1161
      },
      {
        t: 'Jezus',
        n: 2424
      },
      {
        n: 4314
      },
      {
        t: 'mu',
        n: 846
      },
      {
        t: 'odpowiedział:',
        n: 611
      },
      {
        t: 'Ustąp',
        n: 863
      },
      {
        t: 'teraz,',
        n: 737
      },
      {
        t: 'bo',
        n: 1063
      },
      {
        n: 3779
      },
      {
        t: 'godzi się',
        n: [
          2076,
          4241
        ]
      },
      {
        t: 'nam',
        n: 2254
      },
      {
        t: 'wypełnić',
        n: 4137
      },
      {
        t: 'wszelką',
        n: 3956
      },
      {
        t: 'sprawiedliwość.',
        n: 1343
      },
      {
        t: 'Wtedy',
        n: 5119
      },
      {
        t: 'mu',
        n: 846
      },
      {
        t: 'ustąpił.',
        n: 863
      }
    ],
    verse: '15'
  },
  {
    text: [
      {
        na: 1161
      },
      {
        t: 'A',
        tr: 2532
      },
      {
        t: 'gdy'
      },
      {
        t: 'Jezus',
        n: 2424
      },
      {
        t: 'został ochrzczony,',
        n: 907
      },
      {
        t: 'zaraz',
        n: 2117
      },
      {
        t: 'wyszedł',
        n: 305
      },
      {
        t: 'z',
        n: 575
      },
      {
        t: 'wody,',
        n: 5204
      },
      {
        t: 'a',
        n: 2532
      },
      {
        t: 'oto',
        n: 2400
      },
      {
        t: 'otworzyły',
        n: 455,
        with: 2
      },
      {
        t: 'mu',
        n: 846
      },
      {
        t: 'się',
        n: 455,
        with: -2
      },
      {
        t: 'niebiosa',
        n: 3772
      },
      {
        t: 'i',
        n: 2532
      },
      {
        t: 'ujrzał',
        n: 1492
      },
      {
        t: 'Ducha',
        n: 4151
      },
      {
        t: 'Bożego',
        n: 2316
      },
      {
        t: 'zstępującego',
        n: 2597
      },
      {
        t: 'jak',
        n: 5616
      },
      {
        t: 'gołębica',
        n: 4058
      },
      {
        t: 'i',
        n: 2532
      },
      {
        t: 'przychodzącego',
        n: 2064
      },
      {
        t: 'na',
        n: 1909
      },
      {
        t: 'niego.',
        n: 846
      }
    ],
    verse: '16'
  }
];

export const strongLegendData: Map<number, StrongBasic> = new Map([
  [
    305,
    {
      transliteration: "anabainō",
      root: "ἀναβαίνω"
    }
  ],
  [
    455,
    {
      transliteration: "anoigō",
      root: "ἀνοίγω"
    }
  ],
  [
    575,
    {
      transliteration: "apo",
      root: "ἀπό"
    }
  ],
  [
    611,
    {
      transliteration: "apokrinomai",
      root: "ἀποκρίνομαι"
    }
  ],
  [
    737,
    {
      transliteration: "arti",
      root: "ἄρτι"
    }
  ],
  [
    846,
    {
      transliteration: "autos",
      root: "αὐτός"
    }
  ],
  [
    863,
    {
      transliteration: "aphiēmi",
      root: "ἀφίημι"
    }
  ],
  [
    907,
    {
      transliteration: "baptizō",
      root: "βαπτίζω"
    }
  ],
  [
    1063,
    {
      transliteration: "gar",
      root: "γάρ"
    }
  ],
  [
    1161,
    {
      transliteration: "de",
      root: "δέ"
    }
  ],
  [
    1343,
    {
      transliteration: "dikaiosynē",
      root: "δικαιοσύνη"
    }
  ],
  [
    1492,
    {
      transliteration: "eidō",
      root: "εἴδω"
    }
  ],
  [
    1909,
    {
      transliteration: "epi",
      root: "ἐπί"
    }
  ],
  [
    2064,
    {
      transliteration: "erchomai",
      root: "ἔρχομαι"
    }
  ],
  [
    2076,
    {
      transliteration: "esti",
      root: "ἐστί"
    }
  ],
  [
    2117,
    {
      transliteration: "euthys",
      root: "εὐθύς"
    }
  ],
  [
    2254,
    {
      transliteration: "hēmin",
      root: "ἡμῖν"
    }
  ],
  [
    2316,
    {
      transliteration: "theos",
      root: "θεός"
    }
  ],
  [
    2400,
    {
      transliteration: "idou",
      root: "ἰδού"
    }
  ],
  [
    2424,
    {
      transliteration: "Iēsous",
      root: "Ἰησοῦς"
    }
  ],
  [
    2532,
    {
      transliteration: "kai",
      root: "καί"
    }
  ],
  [
    2597,
    {
      transliteration: "katabainō",
      root: "καταβαίνω"
    }
  ],
  [
    3772,
    {
      transliteration: "ouranos",
      root: "οὐρανός"
    }
  ],
  [
    3779,
    {
      transliteration: "hoytō(s)",
      root: "οὕτω(ς)"
    }
  ],
  [
    3956,
    {
      transliteration: "pas",
      root: "πᾶς"
    }
  ],
  [
    4058,
    {
      transliteration: "peristera",
      root: "περιστερά"
    }
  ],
  [
    4137,
    {
      transliteration: "plēroō",
      root: "πληρόω"
    }
  ],
  [
    4151,
    {
      transliteration: "pneuma",
      root: "πνεῦμα"
    }
  ],
  [
    4241,
    {
      transliteration: "prepō",
      root: "πρέπω"
    }
  ],
  [
    4314,
    {
      transliteration: "pros",
      root: "πρός"
    }
  ],
  [
    5119,
    {
      transliteration: "tote",
      root: "τότε"
    }
  ],
  [
    5204,
    {
      transliteration: "hydōr",
      root: "ὕδωρ"
    }
  ],
  [
    5616,
    {
      transliteration: "hōsei",
      root: "ὡσεί"
    }
  ]
]);

export const strongDescription: Map<number, string> = new Map([
  [0, 'Słowo greckie oddane słowem polskim.'],
  [1, 'Słowo greckie występujące tylko w Textus Receptus.'],
  [2, 'Słowo greckie występujące tylko w Nestle Aland.'],
  [3, 'Słowo greckie oddane dwoma lub więcej słowami polskimi.'],
  [4, 'Słowa greckie oddane jednym słowem polskim.'],
  [5, 'Słowo greckie nie oddane w przekładzie.'],
  [6, 'Słowo dopisane, niewystępujące w oryginale.']
]);
