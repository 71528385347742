<div class="book-container">
  <div class="title">
    {{ data.book | abbreviation }}. {{ data.chapter }}@if (data.verse) {
    ,{{ data.verse }}
  }
</div>

<div class="actions">
  @if (!data.verse) {
    <button (click)="selectChapter($event)" (auxclick)="selectChapter($event)">
      <mat-icon svgIcon="icons:refresh"></mat-icon>przejdź do rozdziału
    </button>
  }

  @if (data.verse) {
    <button (click)="selectVerse($event)" (auxclick)="selectVerse($event)">
      <mat-icon svgIcon="icons:refresh"></mat-icon>przejdź do wersetu
    </button>
  }

  @if (data.verse) {
    <button (click)="compareVerse()">
      <mat-icon svgIcon="icons:compare"></mat-icon>porównaj w przekładach
    </button>
  }

  <button (click)="copyChapter(data.verse)">
    <mat-icon svgIcon="icons:content_copy"></mat-icon>kopiuj {{ data.verse ? 'werset' : 'rozdział' }}
  </button>

  <button (click)="removeChapter()">
    <mat-icon svgIcon="icons:delete"></mat-icon>usuń z ulubionych
  </button>
</div>
</div>
