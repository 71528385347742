import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';

import { FavouriteChapter } from '../../../../../services/left-panel/favourite-chapter.model';
import { LeftPanelCopyChapterDialogComponent, QuestionResult } from './copy-chapter-dialog/left-panel-copy-chapter-dialog.component';
import { SelectChapterEvent } from '../bible-left-panel.component';

export interface LeftPanelBottomSheetResult {
  type: LeftPanelBottomSheetResultType;
  data: SelectChapterEvent | FavouriteChapter;
}

export enum LeftPanelBottomSheetResultType {
  OPEN_CHAPTER,
  OPEN_VERSE,
  COPY_CHAPTER_WITH_NUMBERS,
  COPY_CHAPTERS,
  REMOVE_CHAPTER,
  COMPARE_VERSE,
}

@Component({
  templateUrl: './left-panel-bottom-sheet.component.html',
  styleUrls: ['../../../../../../styles/reusable/bottom-sheet.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftPanelBottomSheetComponent {
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: FavouriteChapter,
              private bottomSheetRef: MatBottomSheetRef<LeftPanelBottomSheetComponent>,
              private dialog: MatDialog) {}
  protected selectChapter($event: MouseEvent): void {
    if ($event.button === 0) {
      this.bottomSheetRef.dismiss(this.getResult(LeftPanelBottomSheetResultType.OPEN_CHAPTER));
    } else if ($event.button === 1) {
      this.bottomSheetRef.dismiss(this.getResult(LeftPanelBottomSheetResultType.OPEN_CHAPTER, true));
    }
  }

  protected selectVerse($event: MouseEvent): void {
    if ($event.button === 0) {
      this.bottomSheetRef.dismiss(this.getResult(LeftPanelBottomSheetResultType.OPEN_VERSE));
    } else if ($event.button === 1) {
      this.bottomSheetRef.dismiss(this.getResult(LeftPanelBottomSheetResultType.OPEN_VERSE, true));
    }
  }

  protected copyChapter(copyVerse): void {
    if (copyVerse) {
      this.bottomSheetRef.dismiss(this.getResult(LeftPanelBottomSheetResultType.COPY_CHAPTERS));
    } else {
      this.openCopyDialog();
    }
  }

  protected removeChapter(): void {
    this.bottomSheetRef.dismiss(this.getResult(LeftPanelBottomSheetResultType.REMOVE_CHAPTER));
  }

  protected compareVerse(): void {
    this.bottomSheetRef.dismiss(this.getResult(LeftPanelBottomSheetResultType.COMPARE_VERSE));
  }

  private getResult(type: LeftPanelBottomSheetResultType, openInBlankPage = false): LeftPanelBottomSheetResult {
    return { type, data: openInBlankPage
        ? { selectChapter: this.data, openInBlankPage }
        : this.data
    } as LeftPanelBottomSheetResult;
  }

  private openCopyDialog(): void {
    this.dialog.open<LeftPanelCopyChapterDialogComponent, null, QuestionResult>(LeftPanelCopyChapterDialogComponent, {
    }).afterClosed().subscribe((result: QuestionResult) => {
      if (result === QuestionResult.NO) {
        this.bottomSheetRef.dismiss(this.getResult(LeftPanelBottomSheetResultType.COPY_CHAPTERS));
      } else if (result === QuestionResult.YES) {
        this.bottomSheetRef.dismiss(this.getResult(LeftPanelBottomSheetResultType.COPY_CHAPTER_WITH_NUMBERS));
      }
    });
  }
}
