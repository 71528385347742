import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

export enum ChapterChange {
  PREV, NEXT,
}

export interface ChapterChangeResult {
  change: ChapterChange;
  openInBlankPage?: boolean;
}

@Component({
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChapterButtonComponent {
  @Input({ required: true }) protected readonly tooltip: string;
  @Input({ required: true }) protected readonly tooltipPosition: TooltipPosition = 'below';
  @Input() protected readonly tabIndex = 0;

  @Output() protected readonly chapterChange$ = new EventEmitter<ChapterChangeResult>();

  protected emitChange(change: ChapterChangeResult): void {
    this.chapterChange$.emit(change);
  }
}
