import { BibliaInfoCode } from './biblia-info-code';

export const AVAILABLE_BIBLES_CODES: BibliaInfoCode[] = [
  BibliaInfoCode.BIBLIA_BRZESKA,
  BibliaInfoCode.BIBLIA_GDANSKA,
  BibliaInfoCode.BIBLIA_JAKUBA_WUJKA,
  BibliaInfoCode.BIBLIA_TORUNSKA ,
  BibliaInfoCode.BIBLIA_TYSIACLECIA,
  BibliaInfoCode.BIBLIA_WARSZAWSKA,
  BibliaInfoCode.KING_JAMES_VERSION ,
  BibliaInfoCode.LUBELSKA,
  BibliaInfoCode.NOWA_GDANSKA,
  BibliaInfoCode.PAULISTOW ,
  BibliaInfoCode.POZNANSKA,
  BibliaInfoCode.PRZEKLAD_DOSLOWNY ,
  BibliaInfoCode.PRZEKLAD_LITERACKI ,
  BibliaInfoCode.SEPTUAGINTA,
  BibliaInfoCode.SLOWO_ZYCIA,
  BibliaInfoCode.UWSPOLCZESNIONA_BIBLIA_GDANSKA,
  BibliaInfoCode.VULGATA ,
  BibliaInfoCode.WARSZAWSKO_PRASKA,
  BibliaInfoCode.WEBSTER_BIBLE ,
  BibliaInfoCode.YOUNG_LITERAL,
];
