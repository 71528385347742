import { BreakpointObserver } from '@angular/cdk/layout';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter, inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSelectChange } from '@angular/material/select';

import { BibleForm, BibleGroup, BibleItem } from '../../bible.model';
import { BibliaInfoCode } from '../../../../../services/biblia-info/biblia-info-code';
import { StateService } from '../../../../../services/state/state.service';
import { SubComponent } from '../../../../../components/utils/sub/sub.component';

@Component({
  selector: 'app-bible-filter',
  templateUrl: './bible-filter.component.html',
  styleUrls: ['./bible-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BibleFilterComponent extends SubComponent implements OnChanges, OnInit {
  @Input({ required: true }) protected readonly bibleGroup: BibleGroup[];
  @Input({ required: true }) protected readonly bookGroup: BibleGroup[];
  @Input() protected readonly books: BibleItem[];
  @Input({ required: true }) protected readonly chapters: number[];
  @Input({ required: true }) protected readonly form: FormGroup<BibleForm>;
  @Input() protected readonly fullScreen: boolean;

  @Output() protected readonly bibleSelectionChange$ = new EventEmitter<FormGroup<BibleForm>>();
  @Output() protected readonly bookSelectionChange$ = new EventEmitter<FormGroup<BibleForm>>();
  @Output() protected readonly chapterSelectionChange$ = new EventEmitter<FormGroup<BibleForm>>();
  @Output() protected readonly bibleCompareSelectionChange$ = new EventEmitter<FormGroup<BibleForm>>();
  @Output() protected readonly versesInNewLine$ = new EventEmitter<boolean>();
  @Output() protected readonly copyVerses$ = new EventEmitter<void>();
  @Output() protected readonly openSelectTranslationsDialog$ = new EventEmitter<void>();
  @Output() protected readonly changeFontSize$ = new EventEmitter<number>();
  @Output() protected readonly maxCompareColumns$ = new EventEmitter<number>();

  @ViewChild('expansionPanel', { static: true }) readonly expansionPanel: MatExpansionPanel;

  protected breakpointObserver = inject(BreakpointObserver);

  protected disableDragDrop0 = true;
  protected disableDragDrop1 = true;
  protected disableCompareDragDrop0 = true;
  protected disableCompareDragDrop1 = true;

  protected maxCompareColumns = 1;

  protected mobilePrevCompareValue: string;

  protected get bibleCompare(): BibliaInfoCode[] {
    return this.form.get('bibleCompare').value;
  }

  ngOnInit(): void {
    this.observeBreakpointsChanges();
    this.mobilePrevCompareValue = this.bibleCompare[0];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.bibleGroup?.currentValue) {
      this.initBiblesOrderFromStorage();
    }
    if (changes?.fullScreen) {
      this.adjustMaxCompareColumns();
    }
  }

  protected drop(event: CdkDragDrop<BibleGroup>, i: number): void {
    moveItemInArray(this.bibleGroup[i].options, event.previousIndex, event.currentIndex);
    const order = this.bibleGroup[i].options.map(item => item.abbreviation);
    StateService.setArray<string>(`abbreviation-order-${i === 0 ? 'pl' : 'en'}`, order);
  }

  private initBiblesOrderFromStorage(): void {
    const orderPl = StateService.getArray('abbreviation-order-pl');
    this.bibleGroup[0]?.options.sort((a, b) => {
      return orderPl.findIndex(item => item === a.abbreviation) < orderPl.findIndex(item => item === b.abbreviation) ? -1 : 1
    });

    const orderEn = StateService.getArray('abbreviation-order-en');
    this.bibleGroup[1]?.options.sort((a, b) => {
      return orderEn.findIndex(item => item === a.abbreviation) < orderEn.findIndex(item => item === b.abbreviation) ? -1 : 1
    });
  }

  private observeBreakpointsChanges(): void {
    this.subscription.add(this.breakpointObserver.observe(
      ['(min-width: 1800px)', '(min-width: 1550px)', '(min-width: 1300px)', '(min-width: 1024px)'])
      .subscribe(() => this.adjustMaxCompareColumns()));
  }

  private adjustMaxCompareColumns(): void {
    if (!this.fullScreen) {
      this.maxCompareColumns = 1;
    } else if (this.breakpointObserver.isMatched(['(min-width: 1800px)'])) {
      this.maxCompareColumns = 5;
    } else if (this.breakpointObserver.isMatched(['(min-width: 1550px)'])) {
      this.maxCompareColumns = 4;
    } else if (this.breakpointObserver.isMatched(['(min-width: 1300px)'])) {
      this.maxCompareColumns = 3;
    } else if (this.breakpointObserver.isMatched(['(min-width: 1024px)'])) {
      this.maxCompareColumns = 2;
    } else {
      this.maxCompareColumns = 1;
    }
    this.adjustCompareBibleFormValueToMaxColumn(this.maxCompareColumns);
    this.maxCompareColumns$.emit(this.maxCompareColumns);
  }

  protected mobileSelectionChange(change: MatSelectChange): void {
    if (this.bibleCompare?.length > 1) {
      const code = this.bibleCompare.find(code => code !== this.mobilePrevCompareValue);
      this.form.get('bibleCompare').setValue([code]);
    }
    this.mobilePrevCompareValue = this.bibleCompare[0];
    this.bibleCompareSelectionChange$.emit(this.form);
    change.source.close();
  }

  private adjustCompareBibleFormValueToMaxColumn(maxColumns: number): void {
    const value = this.form.get('bibleCompare').value;
    if (value.length > 0) {
      const bibleCompare = (this.form.get('bibleCompare').value)
        .filter((item, i) => i < maxColumns);
      this.form.get('bibleCompare').setValue([...bibleCompare.values()]);
    }
  }
}
