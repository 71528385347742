import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { BibleContentBottomSheetResultType } from '../../content/bottom-sheet/bible-content-bottom-sheet.component';
import { BibliaInfoChapterModel } from '../../../../../services/biblia-info/biblia-info.model';

export interface CompareVerseBottomSheetResult {
  type: BibleContentBottomSheetResultType;
  data: BibliaInfoChapterModel;
}

@Component({
  templateUrl: './compare-verse-bottom-sheet.component.html',
  styleUrls: ['./compare-verse-bottom-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareVerseBottomSheetComponent {
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: { item: BibliaInfoChapterModel, isInStorage: boolean },
              private bottomSheetRef: MatBottomSheetRef<CompareVerseBottomSheetComponent>) {}

  selectVerse(): void {
    this.bottomSheetRef.dismiss(this.getResult(BibleContentBottomSheetResultType.COPY_VERSE));
  }

  addVerseToStorage(): void {
    this.bottomSheetRef.dismiss(this.getResult(BibleContentBottomSheetResultType.ADD_VERSE));
  }

  removeVerseFromStorage(): void {
    this.bottomSheetRef.dismiss(this.getResult(BibleContentBottomSheetResultType.REMOVE_VERSE));
  }

  private getResult(type: BibleContentBottomSheetResultType): CompareVerseBottomSheetResult {
    return { type, data: this.data.item } as CompareVerseBottomSheetResult;
  }
}
