import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { BibleContentBottomSheetComponent } from './bible-content-bottom-sheet.component';
import { MediaDirectivesModule } from '../../../../../directives/media/media-directives.module';
import { SharedPipesModule } from '../../../../../pipes/shared-pipes.module';

@NgModule({
  declarations: [BibleContentBottomSheetComponent],
  imports: [
    ...BibleContentBottomSheetModule.angular,
    ...BibleContentBottomSheetModule.app,
    ...BibleContentBottomSheetModule.material,
  ],
  exports: [BibleContentBottomSheetComponent],
})
export class BibleContentBottomSheetModule {
  static angular = [CommonModule];

  static app = [
    MediaDirectivesModule,
    RouterModule,
    SharedPipesModule
  ];

  static material = [
    MatBottomSheetModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
  ];
}
