import { MatPaginatorIntl } from '@angular/material/paginator';

const getRangeLabel = (maxResults: { value: number }) => {
  return (page: number, pageSize: number, length: number): string => {
    if (length == 0 || pageSize == 0) {
      return ``;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} z ${maxResults.value}`;
  };
}

export function getPaginatorIntl(maxResults: { value: number }): MatPaginatorIntl {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = 'wyników na stronę:';
  paginatorIntl.nextPageLabel = 'następna strona';
  paginatorIntl.previousPageLabel = 'poprzednia strona';
  paginatorIntl.lastPageLabel = 'ostatnia strona';
  paginatorIntl.firstPageLabel = 'pierwsza strona';
  paginatorIntl.firstPageLabel = 'pierwsza strona';
  paginatorIntl.getRangeLabel = getRangeLabel(maxResults);

  return paginatorIntl;
}
