import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';

import { BibleItem } from '../bible.model';
import { FavouriteChapter } from '../../../../services/left-panel/favourite-chapter.model';
import { LeftPanelService } from '../../../../services/left-panel/left-panel.service';
import {
  LeftPanelBottomSheetComponent,
  LeftPanelBottomSheetResult,
  LeftPanelBottomSheetResultType
} from '../left-panel/bottom-sheet/left-panel-bottom-sheet.component';
import { SelectChapterEvent } from '../left-panel/bible-left-panel.component';
import { SubComponent } from '../../../../components/utils/sub/sub.component';
import { VersesListDialogComponent } from '../left-panel/verses-list-dialog/verses-list-dialog.component';
import { VersesListDialogService } from '../../../../services/verses-list-dialog.service';

export interface BibleFavouritesMobileDialogData {
  favouriteChapters: FavouriteChapter[];
  books: BibleItem[];
  lastChapters: FavouriteChapter[];
}

export interface BibleFavouritesMobileDialogResult {
  type: BibleFavouritesMobileDialogResultType;
  data: SelectChapterEvent | FavouriteChapter;
}

export enum BibleFavouritesMobileDialogResultType {
  selectChapter, selectVerse, copyChapter, copyChapterWithNumbers, compareVerse
}

@Component({
  selector: 'app-bible-favourites-mobile',
  templateUrl: './bible-favourites-mobile-dialog.component.html',
  styleUrls: ['./bible-favourites-mobile-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BibleFavouritesMobileDialogComponent extends SubComponent implements OnInit {
  protected verseListName = VersesListDialogComponent.defaultListName;

  constructor(private bottomSheet: MatBottomSheet,
              private cdr: ChangeDetectorRef,
              @Inject(MAT_DIALOG_DATA) protected data: BibleFavouritesMobileDialogData,
              private dialog: MatDialog,
              protected dialogRef: MatDialogRef<BibleFavouritesMobileDialogComponent, BibleFavouritesMobileDialogResult>,
              private leftPanelService: LeftPanelService,
              private versesListDialogService: VersesListDialogService,) {
    super();
  }

  ngOnInit(): void {
    this.observeCurrentVersesList();
  }

  drop(event: CdkDragDrop<FavouriteChapter[]>): void {
    moveItemInArray(this.data.favouriteChapters, event.previousIndex, event.currentIndex);
    this.leftPanelService.setFavouriteChapters(this.data.favouriteChapters);
  }

  openBottomSheet(item: FavouriteChapter): void {
    this.bottomSheet.open(LeftPanelBottomSheetComponent, { data: item }).afterDismissed().subscribe((result: LeftPanelBottomSheetResult) => {
      if (!result) {
        return;
      }
      switch(result.type) {
        case LeftPanelBottomSheetResultType.OPEN_CHAPTER: this.selectChapter(result.data); break;
        case LeftPanelBottomSheetResultType.OPEN_VERSE: this.selectVerse(result.data); break;
        case LeftPanelBottomSheetResultType.COPY_CHAPTER_WITH_NUMBERS: this.copyChapterWithNumbers(result.data as FavouriteChapter); break;
        case LeftPanelBottomSheetResultType.COPY_CHAPTERS: this.copyChapter(result.data as FavouriteChapter); break;
        case LeftPanelBottomSheetResultType.REMOVE_CHAPTER: this.removeChapter(result.data as FavouriteChapter); break;
        case LeftPanelBottomSheetResultType.COMPARE_VERSE: this.compareVerse(result.data as FavouriteChapter); break;
      }
    });
  }

  protected openVersesLists(): void {
    this.dialog.open(VersesListDialogComponent, { panelClass: ['reading', 'full'] });
  }

  protected selectChapter(data: SelectChapterEvent | FavouriteChapter): void {
    this.dialogRef.close({
      type: BibleFavouritesMobileDialogResultType.selectChapter, data
    });
  }

  private selectVerse(data: SelectChapterEvent | FavouriteChapter): void {
    this.cdr.detectChanges();
    setTimeout(() => {
      this.dialogRef.close({
        type: BibleFavouritesMobileDialogResultType.selectVerse, data
      });
    }, 500);
  }

  private copyChapterWithNumbers(data: FavouriteChapter): void {
    this.dialogRef.close({
      type: BibleFavouritesMobileDialogResultType.copyChapterWithNumbers, data
    });
  }

  private copyChapter(data: FavouriteChapter): void {
    this.dialogRef.close({
      type: BibleFavouritesMobileDialogResultType.copyChapter, data
    });
  }

  private removeChapter(item: FavouriteChapter): void {
    this.leftPanelService.removeFavouriteChapter(item);
    this.cdr.markForCheck();
  }

  private compareVerse(data: FavouriteChapter): void {
    this.dialogRef.close({
      type: BibleFavouritesMobileDialogResultType.compareVerse, data
    });
  }

  private observeCurrentVersesList(): void {
    this.subscription.add(
      this.versesListDialogService.currentList$.subscribe(listName => {
        this.verseListName = listName;
        this.data.favouriteChapters = this.leftPanelService.favouriteChapters;
        this.cdr.markForCheck();
      })
    );
  }
}
