import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { FavouriteChapter } from './left-panel/favourite-chapter.model';
import { SnackbarService } from '../components/snackbar/snackbar.service';
import { StateService } from './state/state.service';
import { VersesListDialogComponent } from '../pages/common/bible/left-panel/verses-list-dialog/verses-list-dialog.component';
import { SnackBarType } from '../components/snackbar/snackbar-type.enum';

export interface VersesListData { [key: string]: FavouriteChapter[] }

@Injectable()
export class VersesListDialogService {
  private currentListSubject: BehaviorSubject<string>;
  private versesListSubject: BehaviorSubject<VersesListData>;

  get currentList$(): Observable<string> { return this.currentListSubject.asObservable(); }

  get currentList(): string { return localStorage.getItem('current-list') || VersesListDialogComponent.defaultListName; }
  get versesList(): VersesListData { return StateService.getItem('verses-list'); }

  constructor(private snackbarService: SnackbarService) {
    this.currentListSubject = new BehaviorSubject<string>(
      Object.keys(this.versesList)?.includes(this.currentList) ? this.currentList : VersesListDialogComponent.defaultListName
    );
    this.versesListSubject = new BehaviorSubject<VersesListData>(this.versesList);
  }

  setCurrentList(listName: string): void {
    localStorage.setItem('current-list', listName);
    this.currentListSubject.next(listName);
  }

  getCurrentListChapters(): FavouriteChapter[] {
    const lists = StateService.getItem('verses-list');
    return lists[this.currentList] || [];
  }

  isDefaultList(): boolean {
    return this.currentList === VersesListDialogComponent.defaultListName;
  }

  setList(listName: string, value = [], addMessage = false): void {
    StateService.setItem('verses-list', listName, value);
    if (addMessage) {
      this.snackbarService.open(`Dodano listę ${listName}.`, SnackBarType.ADD, 2000);
    }
  }

  removeList(listName: string): void {
    const lists = StateService.getItem('verses-list');
    delete lists[listName];
    localStorage.setItem('verses-list', JSON.stringify(lists));
    this.snackbarService.open(`Usunięto listę "${listName}".`, SnackBarType.REMOVE, 2000);
  }

  setListChapter(value: FavouriteChapter): void {
    const versesList = this.versesList;
    const currentListChapters = versesList[this.currentList];
    if (currentListChapters) {
      if (currentListChapters?.length > 30) {
        this.snackbarService.open('Zapisano maksymalną liczbę 30 fragmentów.', SnackBarType.ERROR, 2000);
      } else {
        this.snackbarService.open(`Dodano ${!!value.verse ? 'werset' : 'rozdział'} do listy "${this.currentList}".`, SnackBarType.ADD, 2000);
        versesList[this.currentList].push(value);
        this.setList(this.currentList, versesList[this.currentList])
      }
    }
  }

  removeListChapter(value: FavouriteChapter): void {
    const versesList = this.versesList;
    const currentListChapters = versesList[this.currentList];
    const index = currentListChapters.findIndex(list => {
      return list.bible === value.bible
      && list.book === value.book
      && list.chapter === value.chapter
      && list.verse === value.verse
    });
    if (index !== -1) {
      currentListChapters.splice(index, 1);
      this.snackbarService.open(`Usunięto ${!!value.verse ? 'werset' : 'rozdział'} z listy "${this.currentList}".`, SnackBarType.REMOVE, 2000);
      this.setList(this.currentList, currentListChapters);
    } else {
      this.snackbarService.open(`Nie znaleziono ${!!value.verse ? 'wersetu' : 'rozdziału'} na liście.`, SnackBarType.ERROR, 2000);
    }
  }
}
