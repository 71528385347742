<div class="actions">
  <app-prev-chapter [hide]="hidePrevButton"
                    [tooltip]="hidePrevButton ? null : 'rozdział ' + (chapter - 1)"
                    tooltipPosition="left"
                    (chapterChange$)="chapterChange$.emit($event.change)">
  </app-prev-chapter>

  <div>
    <div class="actions-center">
      <app-icon-button label="ustawienia" tooltip="ustawienia" (click$)="openSettings$.emit()" icon="settings"
      ></app-icon-button>

      <app-icon-button label="wybór ksiąg biblijnych" tooltip="księgi biblijne" (click$)="openBibleBookDialog$.emit()"
                       icon="import_contacts"
      ></app-icon-button>

      <app-icon-button label="instrukcja" tooltip="instrukcja" (click$)="openLegend$.emit()" icon="info"
      ></app-icon-button>
    </div>
  </div>

  <app-next-chapter [hide]="hideNextButton"
                    [tooltip]="hideNextButton ? null : 'rozdział ' + (chapter + 1)"
                    tooltipPosition="right"
                    (chapterChange$)="chapterChange$.emit($event.change)">
  </app-next-chapter>
</div>
