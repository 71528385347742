import { BibleTranslation } from '../../pages/common/bible/bible.model';
import { BibliaInfoChapterModel } from './biblia-info.model';
import { BOOKS_NAMES } from './available-books';
import { CustomBibleCode } from './biblia-info-code';
import { VerseUbg } from '../strong/strong.model';

export const customTranslations = new Map([
  [CustomBibleCode.UBG_STRONGA, 'UBG z kodami Stronga']
]);

export const ubgTranslation: BibleTranslation = {
  abbreviation: CustomBibleCode.UBG_STRONGA,
  description: 'Uwspółcześniona Biblia Gdańska, opracowana przez Fundację Wrota Nadziei. Kody Stronga przypisane przez platformę Apologetyka Biblijna.',
  language: 'pl',
  name: customTranslations.get(CustomBibleCode.UBG_STRONGA),
};

export const getUbgBibliaInfoChapter = (bookAbbreviation, chapter: number, verses: VerseUbg[]): BibliaInfoChapterModel => {
  return {
    bible: {
      abbreviation: ubgTranslation.abbreviation,
      name: ubgTranslation.name
    },
    book: {
      abbreviation: bookAbbreviation,
      name: BOOKS_NAMES.get(bookAbbreviation).name,
    },
    chapter,
    type: 'Wersety',
    verses: verses.map(verseItem => ({
      text: verseItem.text
        .filter(wordItem => !!wordItem.t)
        .map(wordItem => wordItem.t)
        .join(' '),
      verse: verseItem.verse,
    })),
    verses_range: `1-${verses.length}`
  }
};
