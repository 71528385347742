import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { BreakpointsService } from '../breakpoints.service';
import { StateService } from '../state/state.service';
import { StrongState } from './strong-state.model';
import { StrongComponent } from '../../pages/common/strong/strong.component';

@Injectable({
  providedIn: 'root'
})
export class StrongStateService {
  private static getStrongActionsItem(): StrongState {
    return StateService.getItem('strong-actions');
  }

  private static setStrongActionsItem(propertyKey: string, value: any): void {
    StateService.setItem('strong-actions', propertyKey, value);
  }

  private _compactMode$: BehaviorSubject<boolean>;
  private _fullScreen$: BehaviorSubject<boolean>;
  private _showGreek$: BehaviorSubject<boolean>;
  private _showStrong$: BehaviorSubject<boolean>;
  private _showTransliteration$: BehaviorSubject<boolean>;

  private _compactMode: boolean;
  private _fullScreen: boolean;
  private _showGreek: boolean;
  private _showStrong: boolean;
  private _showTransliteration: boolean;

  get compactMode$(): Observable<boolean> { return this._compactMode$.asObservable(); }
  get fullScreen$(): Observable<boolean> { return this._fullScreen$.asObservable(); }
  get showGreek$(): Observable<boolean> { return this._showGreek$.asObservable(); }
  get showStrong$(): Observable<boolean> { return this._showStrong$.asObservable(); }
  get showTransliteration$(): Observable<boolean> { return this._showTransliteration$.asObservable(); }

  get compactMode(): boolean { return this._compactMode; }
  get fullScreen(): boolean { return this._fullScreen; }
  get showGreek(): boolean { return this._showGreek; }
  get showStrong(): boolean { return this._showStrong; }
  get showTransliteration(): boolean { return this._showTransliteration; }

  constructor(private breakpointsService: BreakpointsService) {
    this.initActions();
  }

  private initActions(): void {
    const actionsItem = StrongStateService.getStrongActionsItem();

    this._compactMode = (this.breakpointsService.isDesktop ? !!actionsItem.compactMode : false) || false;
    this._fullScreen = (this.breakpointsService.isDesktop ? !!actionsItem.fullScreen : false) || false;
    this._fullScreen = (this.breakpointsService.isDesktop ? !!actionsItem.fullScreen : false) || false;
    this._showGreek = !!actionsItem.showGreek || true;
    this._showStrong = !!actionsItem.showStrong || true;
    this._showTransliteration = !!actionsItem.showTransliteration || true;

    this._compactMode$ = new BehaviorSubject<boolean>(this._compactMode);
    this._fullScreen$ = new BehaviorSubject<boolean>(this._fullScreen);
    this._showGreek$ = new BehaviorSubject<boolean>(this._fullScreen);
    this._showStrong$ = new BehaviorSubject<boolean>(this._fullScreen);
    this._showTransliteration$ = new BehaviorSubject<boolean>(this._fullScreen);
  }

  setCompactMode(value: boolean): void {
    this._compactMode = value;
    StrongStateService.setStrongActionsItem('compactMode', value);
    this._compactMode$.next(value);
  }

  setFullScreen(value: boolean): void {
    this._fullScreen = value;
    StrongStateService.setStrongActionsItem('fullScreen', value);
    this._fullScreen$.next(value);
  }

  setShowGreek(value: boolean): void {
    this._showGreek = value;
    StrongStateService.setStrongActionsItem('showGreek', value);
    this._showGreek$.next(value);
  }

  setShowStrong(value: boolean): void {
    this._showStrong = value;
    StrongStateService.setStrongActionsItem('showStrong', value);
    this._showStrong$.next(value);
  }

  setShowTransliteration(value: boolean): void {
    this._showTransliteration = value;
    StrongStateService.setStrongActionsItem('showTransliteration', value);
    this._showTransliteration$.next(value);
  }
}
