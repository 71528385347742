<form [formGroup]="form" mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>
      Wpisz nazwę księgi żeby rozpocząć...
    </mat-label>

    <input #input type="text" matInput formControlName="name" aria-label="Wyszukaj księgę z Biblii"
           [matAutocomplete]="autoGroup" #trigger="matAutocompleteTrigger">

    @if (selectedBook) {
      <p class="max">max: {{ maxBookChapters }}</p>
    }

    <mat-autocomplete #autoGroup="matAutocomplete" class="background" (optionSelected)="optionSelected($event);">
      @for (item of filteredBooks | async; track item; let i = $index) {
        @if (item | isNumber) {
          <mat-option [value]="selectedBook + ' ' + item">
            {{ item }}
          </mat-option>
        } @else {
          <mat-option [value]="item[1].name" [class.break]="breakIndexes.includes(i)">
            {{ item[1].name }} {{ oddIndex }}
          </mat-option>
        }
      }
    </mat-autocomplete>
  </mat-form-field>
</form>
