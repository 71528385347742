@if (('font-size-' + fontSize); as fontClass) {
  <div class="center">
    <app-bible-actions
      [hidePrevButton]="hidePrevButton"
      [hideNextButton]="hideNextButton"
      [isFavouriteChapter]="isFavouriteChapter"
      [chapter]="chapterItem?.chapter"
      [hideActions]="hideActions"
      (chapterChange$)="chapterChange($event)"
      (favouriteChapterChange$)="favouriteChapterChange$.emit($event)"
      (openToolsPanel$)="openToolsPanel$.emit()"
      (openBibleBookDialog$)="openBibleBookDialog$.emit()"
      (openSelectTranslationsDialog$)="openSelectTranslationsDialog$.emit()"
      (copyVerses$)="copyVerses()"
      (changeFontSize$)="changeFontSize($event)"
      (fullScreen$)="fullScreenMode($event)"
      (versesInNewLine$)="setVerseInNewLine($event)"
      [class.full-screen]="fullScreen"
      [class.absolute]="actionsAbsolute"
      >
    </app-bible-actions>
    @if (chapterItem) {
      <div class="text-container" [class.verses-in-new-line]="versesInNewLine">
        <p [class]="'left-column ' + fontClass" (mouseleave)="onMouseLeave()">
          <span *onlyDesktop class="bible-header">{{ chapterItem?.bible.name }}</span>
          @for (verse of chapterItem?.verses; track verse.verse; let i = $index) {
            <span [class.underline]="!versesUbg"
              [class.active]="activeVerseIndex === verse.verse" class="verse-container"
              (click)="openBottomSheet(verse.text, +verse.verse)" (mouseenter)="onMouseEnter(verse.verse)">
              <ng-container *ngTemplateOutlet="content; context: { verse: verse, i: i }"></ng-container>
            </span>
          }
        </p>
        @for (item of displayCompareItems | keyvalue; track item.key; let first = $first) {
          <p *onlyDesktop [class]="fontClass" [class.first]="first && displayCompareItems.size > 1" (mouseleave)="onMouseLeave()"
            [class.right-column]="first" [class.extra-column]="!first">
            <span class="bible-header">{{ item.value?.bible.name }}</span>
            @for (verse of item.value?.verses; track verse.verse) {
              <span class="underline"
                [class.active]="activeVerseIndex === verse.verse"
                (click)="openBottomSheet(verse.text, +verse.verse, true)" (mouseenter)="onMouseEnter(verse.verse)">
                <span class="verse-number">({{ verse.verse }})</span>
                {{ ' ' }}<span [innerHTML]="verse.text"></span>{{' '}}
              </span>
            }
          </p>
          @if (first) {
            <p *onlyPortraitLandscapeTablet [class]="fontClass" class="right-column" (mouseleave)="onMouseLeave()">
              @for (verse of item.value?.verses; track verse.verse) {
                <span class="underline"
                  [class.active]="activeVerseIndex === verse.verse"
                  (click)="openBottomSheet(verse.text, +verse.verse, true)" (mouseenter)="onMouseEnter(verse.verse)">
                  <span class="verse-number">({{ verse.verse }})</span>
                  {{ ' ' }}<span [innerHTML]="verse.text"></span>{{' '}}
                </span>
              }
            </p>
          }
        }
      </div>
    }
    <div *onlyPortrait class="text-container portrait-two-columns">
      @if (compareItems) {
        @for (verse of chapterItem?.verses; track verse.verse) {
          <div class="separator">{{ verse.verse }}</div>
          <p class="column-up" [class]="fontClass" (click)="openBottomSheet(verse.text, +verse.verse)">
            <span class="verse-number">({{ chapterItem.bible.abbreviation | uppercase }})</span>
            {{ ' ' }}<span [innerHTML]="verse.text"></span>{{' '}}
          </p>
          <p class="column-down" [class]="fontClass"
            (click)="openBottomSheet(firstCompareItem[1]?.verses[+verse.verse - 1].text, +verse.verse, true)">
            <span class="verse-number">({{ firstCompareItem[0] | uppercase }})</span>
            {{ ' ' }}<span [innerHTML]="firstCompareItem[1]?.verses[+verse.verse - 1]?.text"></span>{{' '}}
          </p>
        }
      }
    </div>
  </div>
}

@if (!isLoading && description && chapterItem) {
  <div class="center description-container">
    <app-icon-button label="opis przekładu biblijnego" tooltip="opis przekładu" icon="info" class="alt-hover"
                     (click$)="openTranslationInfoDialog()"
    ></app-icon-button>
  </div>
}

<app-loader [isLoading]="isLoading || isDialogLoading"></app-loader>

<ng-template #content let-verse="verse" let-i="i">
  <span class="verse-number" [id]="verse.verse">
    @if (versesUbg && !versesInNewLine) {
      &nbsp;
      }({{ verse.verse }})
    </span>

    @if (versesUbg) {
      @for (word of versesUbg[i].text; track word) {
        @if (word.t) {
          <span class="ubg-verse">
            &nbsp;<button class="ubg-text" (click)="$event.stopPropagation(); getNumber(word.n || word.tr || word.na)"
            [class.italic]="!word.n && !word.tr">
            {{ word.t }}
            @if (word.n || word.tr) {
              <span class="ubg-strong">{{ word.n || word.tr }}</span>
            }
          </button>
        </span>
      }
    }
  } @else {
    {{ ' ' }}<span [innerHTML]="verse.text"></span>{{' '}}
  }

</ng-template>
