import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { ButtonComponent } from '../../../../components/button/button.component';

@Component({
  templateUrl: './select-number-dialog.component.html',
  styleUrls: ['./select-number-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ButtonComponent,
    MatDialogModule,
  ]
})
export class SelectNumberDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public numbers: number[],
    protected readonly dialogRef: MatDialogRef<SelectNumberDialogComponent, number>,
  ) {}
}
