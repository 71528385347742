import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

@Injectable()
export class StrongScrollService {
  private readonly _scrollToAnchor = new Subject<number>();

  get scrollToAnchor$(): Observable<number> {
    return this._scrollToAnchor.asObservable();
  }

  scrollToAnchor(anchor: number): void {
    this._scrollToAnchor.next(anchor);
  }
}
