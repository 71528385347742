<div mat-dialog-title>
  <h1 *onlyDesktopTabletLandscape>
    <mat-icon svgIcon="icons:content_copy"></mat-icon>

    {{ data.book.name }} {{ data.chapter || 1 }}
  </h1>

  <h1 *onlyPortrait>
    <mat-icon svgIcon="icons:content_copy"></mat-icon>

    {{ data.book.abbreviation | abbreviation }}. {{ data.chapter || 1 }}
  </h1>

  <app-icon-button label="zamknij okno" icon="close" class="icon-large close" (click$)="dialogRef.close()"></app-icon-button>
</div>

<div mat-dialog-content>
  <div class="numbers-container">
    @for (number of (data.max | array); track number) {
      <button (click)="selectVerse(number)"
        class="number" [class.active]="from === number || to === number"
        [class.sub-active]="from && to && from < number && to > number">
        {{ number }}
      </button>
    }
  </div>

  <div class="verses-container">
    <div class="number-container">
      <div class="scope-number">{{ from }}</div>

      <mat-icon svgIcon="icons:right"></mat-icon>

      <div class="scope-number">{{ to }}</div>
    </div>

    <div class="end-container">
      <mat-checkbox [(ngModel)]="keepVerses">
        <ng-container *onlyDesktopTablet>zachowaj numery wersetów</ng-container>

        <ng-container *onlyPortraitLandscape>numery wersetów</ng-container>
      </mat-checkbox>

      <app-button [disabled]="!from || !to" icon="content_copy" text="kopiuj"
                  (click$)="dialogRef.close({ from, to, keepVerses })"
      ></app-button>
    </div>
  </div>
</div>
