<button (click)="selectVerse()">
  <mat-icon svgIcon="icons:content_copy"></mat-icon>kopiuj werset
</button>

@if (!data.isInStorage) {
  <button (click)="addVerseToStorage()">
    <mat-icon svgIcon="icons:add_circle_outline"></mat-icon>dodaj do ulubionych
  </button>
}

@if (data.isInStorage) {
  <button (click)="removeVerseFromStorage()">
    <mat-icon svgIcon="icons:delete"></mat-icon>usuń z ulubionych
  </button>
}
