import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import {
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';

import { BreakpointsService } from '../../../../../services/breakpoints.service';
import { IconButtonComponent } from '../../../../../components/icon-button/icon-button.component';
import { MediaDirectivesModule } from '../../../../../directives/media/media-directives.module';
import { SharedPipesModule } from '../../../../../pipes/shared-pipes.module';
import { StrongBasic, VerseUbg, WordUbg } from '../../../../../services/strong/strong.model';
import { strongDescription, strongLegendData, strongLegendExample } from './strong-example';
import { StrongDescriptionPipe } from './strong-description.pipe';
import { StrongNumberPipe, StrongRootPipe, StrongTransliterationPipe } from '../../strong.pipe';

@Component({
  selector: 'app-strong-settings',
  templateUrl: './strong-legend.component.html',
  styleUrls: [
    '../../strong.component.scss',
    './strong-legend.component.scss',
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [StrongDescriptionPipe],
  imports: [
    CommonModule,
    IconButtonComponent,
    MatIconModule,
    MatDialogModule,
    MatTooltipModule,
    MediaDirectivesModule,
    SharedPipesModule,
    StrongDescriptionPipe,
    StrongNumberPipe,
    StrongRootPipe,
    StrongTransliterationPipe,
  ]
})
export class StrongLegendComponent implements AfterViewInit {
  protected readonly example: VerseUbg[] = strongLegendExample;
  protected readonly numbersMap: Map<number, StrongBasic> = strongLegendData;
  protected readonly strongDescription: Map<number, string> = strongDescription;

  @HostBinding('class.description-visible') protected strongDescriptionForMobile: string;

  protected connectorWidthPx: number;
  protected descriptionIndex: number;
  protected relatedIndex: number;
  protected relatedVerse: number;

  protected readonly breakpointsService = inject(BreakpointsService);
  protected readonly dialogRef = inject(MatDialogRef<StrongLegendComponent, void>);
  private readonly elementRef = inject(ElementRef);
  private readonly strongDescriptionPipe = inject(StrongDescriptionPipe);

  ngAfterViewInit(): void {
    this.initWordsConnectorOnDesktop();
  }

  protected mouseenter(i: number, verse: number): void {
    this.descriptionIndex = 0;
    this.relatedIndex = i;
    this.relatedVerse = verse;
  }

  protected mouseleave(): void {
    this.descriptionIndex = null;
    this.relatedIndex = null;
    this.relatedVerse = null;
  }

  protected focusin(word: WordUbg): void {
    this.strongDescriptionForMobile = strongDescription.get(this.strongDescriptionPipe.transform(word));
  }

  protected focusout(): void {
    this.strongDescriptionForMobile = null;
  }

  private initWordsConnectorOnDesktop(): void {
    if (this.breakpointsService.isDesktop) {
      const connectButtons: NodeList = this.elementRef.nativeElement.querySelectorAll('.connect');
      const width1 = (connectButtons.item(0) as HTMLDivElement).clientWidth;
      const width2 = (connectButtons.item(1) as HTMLDivElement).clientWidth;
      const width3 = (connectButtons.item(2) as HTMLDivElement).clientWidth;
      this.connectorWidthPx = width1 / 2 + width2 + width3 / 2;
    }
  }
}
