import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';

import { BibleQueryService } from '../../../services/bible-query.service';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export abstract class DisableBibleQueryComponent implements OnInit, OnDestroy {
  private readonly bibleQueryService = inject(BibleQueryService);

  ngOnInit() {
    this.bibleQueryService.disable();
  }

  ngOnDestroy(): void {
    this.bibleQueryService.enable();
  }
}
