<div mat-dialog-title>
  <h1>
    <mat-icon svgIcon="icons:info"></mat-icon>
    Instrukcja
  </h1>

  <app-icon-button label="zamknij okno" icon="close" class="icon-large close" (click$)="dialogRef.close()"></app-icon-button>
</div>

<div mat-dialog-content class="content">
  <section class="section-container">
    <div class="text-container">
      @for (verseItem of example; track verseItem; let verse = $index) {
        <div class="chapter">
          @for (word of verseItem.text; track word; let first = $first; let i = $index) {
            <div class="word"
              [class.disabled]="!word.n && !word.tr && !word.na"
              [class.nestle-aland]="word.na"
              [class.textus-receptus]="word.tr"
              [class.related]="relatedIndex === i && verse === 1"
              [class.connect]="verse === 1 && (i === 11 || i === 12 || i === 13)"
              [matTooltip]="breakpointsService.isDesktop ? strongDescription.get(word | strongDescription) : undefined"
              matTooltipClass="tooltip-large"
              [tabindex]="0"
              (mouseenter)="mouseenter(i + word.with, +verseItem.verse)"
              (mouseleave)="mouseleave()"
              (focusin)="focusin(word)"
              (focusout)="focusout()">
              <div class="word-number">{{ word | strongNumber }}</div>
              <div class="word-transliteration word-root">
                {{ word | strongRoot : numbersMap }}
              </div>
              <div class="word-transliteration">
                {{ word | strongTransliteration : numbersMap }}
              </div>
              <div class="word-translation">{{ word.t || '-' }}</div>
              <ng-container *onlyDesktop>
                @if (verse === 1 && i === 11) {
                  <div class="connector" [style.width.px]="connectorWidthPx"></div>
                }
              </ng-container>
            </div>
          }
        </div>
      }
    </div>
  </section>

  <section>
    <p class="no-padding">Aktualnie obsługujemy następujące scenariusze:</p>

    <ul>
      @for (desc of strongDescription | keyvalue; track desc.key) {
        <li>{{ desc.value }}</li>
      }
    </ul>
  </section>
</div>

<div *onlyPortraitLandscapeTablet class="mobile-tooltip">
  {{ strongDescriptionForMobile }}
</div>
