import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';

import { BibleComponent } from '../../bible.component';
import { BibliaInfoBibleModel } from '../../../../../services/biblia-info/biblia-info.model';
import { ButtonComponent } from '../../../../../components/button/button.component';
import { DisableBibleQueryComponent } from '../../../../../components/utils/bible-dialog/disable-bible-query.component';
import { IconButtonComponent } from '../../../../../components/icon-button/icon-button.component';
import { SnackbarService } from '../../../../../components/snackbar/snackbar.service';
import { SnackBarType } from '../../../../../components/snackbar/snackbar-type.enum';

export interface SelectBibleTranslationsDialogData {
  translations: BibliaInfoBibleModel[];
  selected: string[];
  current: string;
  compareCurrent: string[];
}

@Component({
  templateUrl: './select-bible-translations-dialog.component.html',
  styleUrls: ['./select-bible-translations-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ButtonComponent,
    IconButtonComponent,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
  ],
})
export class SelectBibleTranslationsDialogComponent extends DisableBibleQueryComponent implements OnInit {
  protected readonly translationsPerLang = new Map<string, BibliaInfoBibleModel[]>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SelectBibleTranslationsDialogData,
    public dialogRef: MatDialogRef<SelectBibleTranslationsDialogComponent, string[]>,
    private snackbarService: SnackbarService,
  ) {
    super()
  }

  protected get bibleLanguageNames(): Map<string, string> {
    return BibleComponent.bibleLanguageNames;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.data.translations.forEach(translation => {
      const translations = this.translationsPerLang.get(translation.language) || [];
      translations.push(translation);
      this.translationsPerLang.set(translation.language, translations);
    });
  }

  protected save(): void {
    this.snackbarService.open('Zapisano ustawienia', SnackBarType.SUCCESSFUL, 2000);
    this.dialogRef.close(this.data.selected);
  }

  protected change(abbreviation: string) {
    const index = this.data.selected.findIndex(item => item === abbreviation);
    if (index === -1) {
      this.data.selected.push(abbreviation);
    } else {
      this.data.selected.splice(index, 1);
    }
  }
}
