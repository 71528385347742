import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';

import { BibliaInfoBook } from '../../../../../services/biblia-info/biblia-info-book';
import { BibliaInfoChapterModel } from '../../../../../services/biblia-info/biblia-info.model';
import { BlueLetterBible } from '../../../../../services/blue-letter-bible';
import { StrongFilterComponent } from '../../../strong/filter/strong-filter.component';

export interface BibleContentBottomSheetResult {
  type: BibleContentBottomSheetResultType;
  openInBlankPage?: boolean;
}

export enum BibleContentBottomSheetResultType {
  COMPARE_VERSE,
  COPY_VERSE,
  ADD_VERSE,
  REMOVE_VERSE,
  OPEN_CHAPTER,
}

export interface BibleContentBottomSheetData {
  item: BibliaInfoChapterModel,
  verseNo: number;
  isInStorage: boolean;
  isBlueLetterBibleAvailable: boolean;
  openChapterOption?: boolean;
}

@Component({
  templateUrl: './bible-content-bottom-sheet.component.html',
  styleUrls: ['../../../../../../styles/reusable/bottom-sheet.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BibleContentBottomSheetComponent implements OnInit {
  protected strongUrl: string;
  protected queryParams: any;
  protected fragment: string;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: BibleContentBottomSheetData,
              protected bottomSheetRef: MatBottomSheetRef<BibleContentBottomSheetComponent>, private router: Router) {}

  ngOnInit(): void {
    this.initStrongRouterLinkData();
  }

  openChapter(): void {
    this.bottomSheetRef.dismiss({ type: BibleContentBottomSheetResultType.OPEN_CHAPTER });
  }

  compareVerse(): void {
    this.bottomSheetRef.dismiss({ type: BibleContentBottomSheetResultType.COMPARE_VERSE });
  }

  selectVerse(): void {
    this.bottomSheetRef.dismiss({ type: BibleContentBottomSheetResultType.COPY_VERSE });
  }

  addVerseToStorage(): void {
    this.bottomSheetRef.dismiss({ type: BibleContentBottomSheetResultType.ADD_VERSE });
  }

  removeVerseFromStorage(): void {
    this.bottomSheetRef.dismiss({ type: BibleContentBottomSheetResultType.REMOVE_VERSE });
  }

  close($event: MouseEvent) {
    if ($event.button === 0) {
      this.bottomSheetRef.afterDismissed().subscribe(() => this.navigateToStrongPage())
    } else if ($event.button === 1) {
      this.openStrongInBlankPage();
    }
    this.bottomSheetRef.dismiss();
  }

  protected getBlueLetterLink(): string {
    return `https://www.blueletterbible.org/kjv/${BlueLetterBible.get(this.data.item.book.abbreviation as BibliaInfoBook)}/${this.data.item.chapter}/${this.data.verseNo}/t_conc_1`
  }

  private initStrongRouterLinkData(): void {
    const isBookAvailableInStrong = StrongFilterComponent.chapters.get(this.data.item.book.abbreviation as BibliaInfoBook) > 0;

    if (isBookAvailableInStrong) {
      this.strongUrl = '../../strong/ubg'
      this.queryParams = {
        ksiega: this.data.item.book.abbreviation,
        rozdzial: this.data.item.chapter
      };
      this.fragment = this.data.verseNo + '';
    }
  }

  private navigateToStrongPage(): void {
    this.bottomSheetRef.dismiss();
    this.router.navigate([this.strongUrl],{
      queryParams: this.queryParams,
      fragment: this.fragment,
    });
  }

  private openStrongInBlankPage(): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['strong/ubg'], {
        queryParams: this.queryParams,
        fragment: this.fragment
      }));
    window.open(url, '_blank');
  }
}
