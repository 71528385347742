/* src/app/pages/common/bible/content/bible-content.component.scss */
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop-tablet {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-desktop-tablet {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden {
    display: none !important;
  }
}
.center.right {
  display: flex;
  justify-content: flex-end;
}
.center.block {
  display: block;
  text-align: center;
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-shadow {
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
}
.image-box {
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  border: 1px solid var(--color-disable);
}
.hover {
  cursor: pointer;
}
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
:host .center {
  position: relative;
  padding: 0;
  text-align: center;
  transition: 800ms;
  max-width: 1000px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  :host .center.description-container {
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  :host .center app-icon-button {
    position: relative;
    left: -4rem;
    top: -12px;
  }
}
:host .center app-prev-chapter {
  top: calc(0.5rem + 1px);
  left: -5rem;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  :host .center app-prev-chapter {
    left: -4rem;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .center app-prev-chapter {
    top: -4.5rem;
    left: -0.5rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .center app-prev-chapter {
    top: -10rem;
    left: -0.5rem;
  }
}
:host .center app-next-chapter {
  top: calc(0.5rem + 1px);
  right: -5rem;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  :host .center app-next-chapter {
    right: -4rem;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .center app-next-chapter {
    top: -4.5rem;
    right: -0.5rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .center app-next-chapter {
    top: -10rem;
    right: -0.5rem;
  }
}
:host .center .text-container {
  display: inline-flex;
  overflow: hidden;
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  :host .center .text-container {
    box-sizing: border-box;
    background: var(--color-background-dark);
    border: 2px solid var(--color-disable);
    -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
    -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
    box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  }
}
@media only screen and (min-width: 768px) {
  :host .center .text-container {
    margin-bottom: 1rem;
  }
}
:host .center .text-container.portrait-two-columns {
  display: none;
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .center .text-container.portrait-two-columns .column-up {
    padding-bottom: 0;
  }
  :host .center .text-container.portrait-two-columns .separator {
    overflow: hidden;
    text-align: center;
    font-size: 1.1rem;
    color: var(--color-brand);
  }
  :host .center .text-container.portrait-two-columns .separator:before,
  :host .center .text-container.portrait-two-columns .separator:after {
    background-color: var(--color-text);
    content: "";
    display: inline-block;
    height: 2px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }
  :host .center .text-container.portrait-two-columns .separator:before {
    right: 0.5em;
    margin-left: -50%;
  }
  :host .center .text-container.portrait-two-columns .separator:after {
    left: 0.5em;
    margin-right: -50%;
  }
}
:host .center .text-container.verses-in-new-line .underline {
  display: block;
  position: relative;
}
:host .center .text-container.verses-in-new-line .underline:first-of-type {
  margin-top: -10px;
}
:host .center .text-container .verse-container {
  line-height: 2.375rem;
  color: var(--color-text);
}
:host .center .text-container .left-column {
  transition: 800ms cubic-bezier(0.79, 0, 0.22, 1);
  width: 100%;
  margin-right: 0;
}
@media only screen and (min-width: 1024px) {
  :host .center .text-container .left-column {
    margin-left: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
:host .center .text-container .right-column,
:host .center .text-container .extra-column {
  transition: 800ms cubic-bezier(0.79, 0, 0.22, 1);
  width: 0;
  margin-left: 0;
  opacity: 0;
  height: 0;
}
@media only screen and (min-width: 1024px) {
  :host .center .text-container .right-column,
  :host .center .text-container .extra-column {
    line-height: 2.375rem;
  }
}
:host .center .text-container .underline.active-transition {
  transition: 600ms cubic-bezier(0.79, 0, 0.22, 1);
}
:host .center .text-container .underline.active {
  background: var(--color-brand-hover);
  color: var(--color-text-background-dark);
}
:host .center .text-container .underline .verse-number {
  color: var(--color-brand);
}
:host .center .text-container .bible-header {
  padding: 0;
  margin-bottom: 0;
  border-color: var(--color-brand);
  border-width: 2px;
  overflow: hidden;
  display: block;
  height: 0;
  text-align: center;
  transition: 800ms cubic-bezier(0.79, 0, 0.22, 1);
}
:host .info {
  margin-right: 1rem;
}
:host p {
  transition: 1600ms cubic-bezier(0.79, 0, 0.22, 1);
}
:host p.font-size-0 {
  font-size: 0.9rem;
}
:host p.font-size-1 {
  font-size: 1rem;
}
:host p.font-size-2 {
  font-size: 1.1rem;
}
:host p.font-size-3 {
  font-size: 1.2rem;
}
:host p.font-size-4 {
  font-size: 1.3rem;
}
@media only screen and (min-width: 1024px) {
  :host-context(.two-columns) .center .text-container {
    padding-right: 1rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host-context(.two-columns) .center .text-container {
    display: none;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host-context(.two-columns) .center .text-container.portrait-two-columns {
    display: block;
  }
}
:host-context(.two-columns) .center .text-container .left-column {
  width: 50%;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host-context(.two-columns) .center .text-container .left-column {
    margin-right: 0.5rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host-context(.two-columns) .center .text-container .left-column {
    margin-right: 0.5rem;
  }
}
:host-context(.two-columns) .center .text-container .right-column {
  width: 50%;
  margin-left: 1rem;
  opacity: 1;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host-context(.two-columns) .center .text-container .right-column {
    margin-left: 0.5rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host-context(.two-columns) .center .text-container .right-column {
    margin-left: 0.5rem;
  }
}
:host-context(.two-columns) .center .text-container .right-column.first {
  margin-left: 0;
}
:host-context(.full-screen) .center {
  max-width: 100%;
}
:host-context(.full-screen) .center .text-container .left-column,
:host-context(.full-screen) .center .text-container .right-column,
:host-context(.full-screen) .center .text-container .extra-column {
  transition:
    0ms max-width,
    width 800ms,
    height 800ms,
    margin 800ms,
    font-size 800ms;
}
:host-context(.full-screen) .center p {
  max-width: 100%;
}
:host-context(.full-screen.two-columns) .center .text-container .extra-column {
  width: 50%;
  margin-left: 1rem;
  opacity: 1;
}
:host-context(.full-screen.two-columns) .center .text-container .bible-header {
  border: 2px solid var(--color-brand);
  padding: 0.2rem;
  margin-bottom: 2rem;
  height: 2rem;
}
:host-context(.ubg) .center .text-container p .verse-container {
  display: inline;
  padding: 0.625rem 0;
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host-context(.ubg) .center .text-container p .verse-container {
    justify-content: center;
  }
}
:host-context(.ubg) .center .text-container p .verse-container .verse-number {
  color: var(--color-bible-verse);
  line-height: 1;
  transition: 400ms cubic-bezier(0.79, 0, 0.22, 1);
  display: inline-block;
  font-size: 1.1rem;
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host-context(.ubg) .center .text-container p .verse-container .verse-number {
    font-size: 1rem;
  }
}
:host-context(.ubg) .center .text-container p .verse-container .verse-number:hover,
:host-context(.ubg) .center .text-container p .verse-container .verse-number:focus {
  filter: brightness(1.5);
  cursor: pointer;
}
:host-context(.ubg) .center .text-container p .verse-container .ubg-verse {
  display: inline-flex;
  align-items: center;
}
:host-context(.ubg) .center .text-container p .verse-container .ubg-text {
  position: relative;
  border: none;
  padding: 0.3rem 0;
}
:host-context(.ubg) .center .text-container p .verse-container .ubg-text.italic {
  color: var(--color-text-disable);
}
:host-context(.ubg) .center .text-container p .verse-container .ubg-text .ubg-strong {
  position: absolute;
  font-size: 0.8rem;
  line-height: 1rem;
  top: -0.9rem;
  width: 100%;
  left: 0;
  opacity: 0;
  color: var(--color-text-disable);
  transition: 400ms cubic-bezier(0.79, 0, 0.22, 1);
}
:host-context(.ubg) .center .text-container p .verse-container .ubg-text:hover.italic,
:host-context(.ubg) .center .text-container p .verse-container .ubg-text:focus.italic {
  background-color: inherit;
  cursor: default;
}
:host-context(.ubg) .center .text-container p .verse-container .ubg-text:hover .ubg-strong,
:host-context(.ubg) .center .text-container p .verse-container .ubg-text:focus .ubg-strong {
  opacity: 1;
}
:host-context(.ubg) .center .text-container.verses-in-new-line p .verse-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
:host-context(.ubg) .center .text-container.verses-in-new-line p .verse-container .ubg-verse {
  display: flex;
}
@media only screen and (min-width: 1024px) {
  :host-context(.loading) .text-container {
    min-height: 100vh;
  }
}
/*# sourceMappingURL=bible-content.component.css.map */
