import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightIndex',
  standalone: true,
})
export class HighlightIndexPipe implements PipeTransform {
  transform(indexes: [number, number][], index: number): boolean {
    return indexes.some(indexesTuple => indexesTuple[0] <= index && indexesTuple[1] >= index);
  }
}
