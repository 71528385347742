<div mat-dialog-title>
  <h1>
    <mat-icon svgIcon="icons:import_contacts"></mat-icon>
    @if (!selectedBook) {
      Księgi biblijne
    }

    @if (selectedBook) {
      {{ selectedBook | abbreviation }}<ng-container *onlyDesktopTabletLandscape>. wybór rozdziału</ng-container><ng-container *onlyPortrait>. rozdział</ng-container>
    }
  </h1>

  <app-icon-button label="zamknij okno" icon="close" class="icon-large close" (click$)="dialogRef.close()"></app-icon-button>
</div>

<div mat-dialog-content #container>
  @if (!selectedBook) {
    @for (group of groupedBooks; track i; let i = $index) {
      <p class="separator">
        @if (i === 0) {
          Nowe Przymierze
        }
        @if (i === 1) {
          Stare Przymierze
        }
        @if (i === 2) {
          Apokryfy
        }
      </p>
      <div class="container">
        @for (book of group | keyvalue : defaultOrder; track book.key; let j = $index) {
          <button
            class="book" (click)="selectBook(book.key)"
            [class.selected]="data.book === book.key" [class.gospel]="i === 0 && j < 4"
            [class.paul]="i === 0 && ((j > 4 && j < 14) || j === 18)"
            [class.personal]="i === 0 && j >= 14 && j < 18"
            [class.common]="i === 0 && j >= 19 && j < 26" [class.torah]="i === 1 && j < 5"
            [class.former-neviim]="i === 1 && [5,6,8,9,10,11].includes(j)"
            [class.latter-neviim]="i === 1 && j > 21"
            [class.kethubim]="i === 1 && (j === 7 || j > 11 && j <= 21)">
            <ng-container *onlyPortraitLandscape>
              {{ isMobileViewSimple ? (book.key | abbreviation) : book.value.name }}
            </ng-container>
            <ng-container *onlyDesktopTablet>
              {{ book.value.name }} <ng-container *onlyDesktop>[{{ book.key }}]</ng-container>
            </ng-container>
          </button>
        }
      </div>
    }
  }

  @if (selectedBook) {
    <div class="container">
      @if (selectedBook) {
        <button (click)="selectedBook = null" class="return book"
          aria-label="wróć do wyboru ksiąg">
          <mat-icon svgIcon="icons:arrow-prev"></mat-icon>
        </button>
      }
      @for (chapter of selectedBook | bookChapters; track chapter) {
        <button [class.selected]="data.chapter === chapter"
          [disabled]="data.chapter === chapter && data.book === selectedBook" class="chapter"
          (click)="dialogRef.close({ chapter, book: selectedBook })">
          {{ chapter }}
        </button>
      }
    </div>
  }

  @if (!selectedBook) {
    <mat-checkbox *onlyPortraitLandscape (click)="toggleView()" [(ngModel)]="isMobileViewSimple">
      Widok uproszczony
    </mat-checkbox>
  }
</div>
