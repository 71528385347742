import { ChangeDetectorRef, Component, EventEmitter, HostBinding, inject, Input, Output } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

import { BreakpointsService } from '../../../../../services/breakpoints.service';
import { ChapterButtonModule } from '../../../bible/chapter-button/chapter-button.module';
import { ChapterChange } from '../../../bible/chapter-button/chapter-button.component';
import { IconButtonComponent } from '../../../../../components/icon-button/icon-button.component';
import { MediaDirectivesModule } from '../../../../../directives/media/media-directives.module';

@Component({
  selector: 'app-strong-actions',
  templateUrl: './strong-actions.component.html',
  styleUrls: ['./strong-actions.component.scss'],
  standalone: true,
  imports: [
    ChapterButtonModule,
    IconButtonComponent,
    MatIconModule,
    MatTooltipModule,
    MediaDirectivesModule,
  ],
})
export class StrongActionsComponent {
  @Input({ required: true }) protected readonly chapter: number;
  @Input({ required: true }) protected readonly hidePrevButton = false;
  @Input({ required: true }) protected readonly hideNextButton = false;
  @Input({ required: true }) @HostBinding('class.is-loaded') protected readonly isLoaded = false;

  @Input({ required: true }) protected actionsAbsolutePositionInPx: number;
  @HostBinding('class.absolute') @Input({ required: true }) protected actionsAbsolute = false;

  @Output() protected readonly chapterChange$ = new EventEmitter<ChapterChange>();
  @Output() protected readonly openSettings$ = new EventEmitter<void>();
  @Output() protected readonly openLegend$ = new EventEmitter<void>();
  @Output() protected readonly openBibleBookDialog$ = new EventEmitter<void>();

  @HostBinding('class.actions-visible') protected actionsVisible = true;

  private readonly breakpointService = inject(BreakpointsService);
  private readonly cdr = inject(ChangeDetectorRef);

  constructor() {
    this.observeScrollPosition();
  }

  private observeScrollPosition(): void {
    fromEvent(window, 'scroll').pipe(
      takeUntilDestroyed(),
      filter(() => this.breakpointService.isLandscape),
    ).subscribe( () => {
      const prev = this.actionsVisible;
      this.actionsVisible = window.scrollY > 400;
      if (prev !== this.actionsVisible) {
        this.cdr.markForCheck();
      }
    });
  }
}
