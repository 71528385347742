<div mat-dialog-title>
  <h1>
    <mat-icon svgIcon="icons:import_contacts"></mat-icon>
    Fragmenty biblijne
  </h1>

  <app-icon-button label="zamknij narzędzia biblijne" icon="close" class="icon-large close" (click$)="dialogRef.close()"></app-icon-button>
</div>

<div mat-dialog-content #container class="spaces-horizontal">
  <button class="item label favourite-label" (click)="openVersesLists()">
    <span class="text">
      <span class="hint">Lista:</span>
      {{ verseListName }}
    </span>

    <mat-icon svgIcon="icons:star"></mat-icon>
  </button>

  <div class="container scroll-container drop-list" cdkDropList (cdkDropListDropped)="drop($event)">
    @for (item of data.favouriteChapters; track $index) {
      <button cdkDrag (click)="openBottomSheet(item)" class="item button box">
        <div class="placeholder" *cdkDragPlaceholder></div>
        <mat-icon cdkDragHandle class="drag-handle-icon" svgIcon="icons:drag_handle"></mat-icon>
        <div class="inner-container">
          <span class="text">
            {{ ((item.book | bookNameInBooks : data.books)) + ' ' + (item.chapter || 1) }}{{ item.verse ? (',' + item.verse) : ''}}
          </span>
          <span>{{ item.bible | uppercase }}</span>
        </div>
      </button>
    }
  </div>

  @if (data.favouriteChapters.length === 0) {
    <div class="no-results">brak</div>
  }

  <div class="item label last-search-label">
    <span class="text">Ostatnio wyszukiwane</span>

    <mat-icon svgIcon="icons:history"></mat-icon>
  </div>

  <div class="container">
    @for (item of data.lastChapters; track $index) {
      <button class="item button last" (click)="selectChapter(item)">
        <span class="ellipsis">
          {{ ((item.book | bookNameInBooks : data.books)) }}{{ ' ' + (item.chapter || 1) }}
        </span>
        <span>{{ item.bible | uppercase }}</span>
      </button>
    }
  </div>
</div>
