import { NgModule } from '@angular/core';

import { ChapterButtonComponent } from './chapter-button.component';
import { IconButtonComponent } from '../../../../components/icon-button/icon-button.component';
import { NextChapterComponent } from './next-chapter/next-chapter.component';
import { PrevChapterComponent } from './prev-chapter/prev-chapter.component';

@NgModule({
  declarations: [
    ChapterButtonComponent,
    NextChapterComponent,
    PrevChapterComponent,
  ],
  imports: [
    IconButtonComponent,
  ],
  exports: [
    ChapterButtonComponent,
    NextChapterComponent,
    PrevChapterComponent,
  ],
})
export class ChapterButtonModule {}
