import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BibliaInfoAbbreviationModel } from '../../../../../services/biblia-info/biblia-info.model';
import { DisableBibleQueryComponent } from '../../../../../components/utils/bible-dialog/disable-bible-query.component';

export interface CopyVersesDialogData {
  book: BibliaInfoAbbreviationModel;
  chapter: number;
  max: number;
}

export interface CopyVersesDialogResult {
  from: number;
  to: number;
  keepVerses: boolean;
}

@Component({
  templateUrl: './copy-verses-dialog.component.html',
  styleUrls: ['./copy-verses-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyVersesDialogComponent extends DisableBibleQueryComponent {
  from: number;
  to: number;
  keepVerses = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: CopyVersesDialogData,
              protected dialogRef: MatDialogRef<CopyVersesDialogComponent, CopyVersesDialogResult>,) {
    super();
  }

  selectVerse(number: number): void {
    if (this.from === number) {
      this.to = number;
    } else if (this.to === number) {
      this.to = null;
    } else if (!this.from) {
      if (this.to && this.to < number) {
        this.from = this.to;
        this.to = number;
      } else {
        this.from = number;
      }
    } else if (!this.to) {
      if (this.from > number) {
        this.to = this.from;
        this.from = number;
      } else {
        this.to = number;
      }
    } else if (number < this.to) {
      this.from = number;
    } else {
      this.to = number;
    }
  }
}
