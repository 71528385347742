<div mat-dialog-title>
  <h1 *onlyDesktopTabletLandscape>{{ data[0].book.name }} {{ data[0].chapter > 0 ? data[0].chapter : '1' }},{{ data[0].verses_range }}</h1>

  <h1 *onlyPortrait>{{ data[0].book.abbreviation | abbreviation }}. {{ data[0].chapter > 0 ? data[0].chapter : '1' }},{{ data[0].verses_range }}</h1>

  <app-icon-button label="zamknij okno" icon="close" class="icon-large close" (click$)="dialogRef.close()"></app-icon-button>
</div>

<div mat-dialog-content cdkDropList (cdkDropListDropped)="drop($event)">
  @for (bible of data; track bible.bible.abbreviation) {
    <div class="content" cdkDrag>
      @if (bible) {
        <div class="placeholder" *cdkDragPlaceholder></div>
        <p *onlyPortraitLandscape class="name">
          <mat-icon cdkDragHandle class="drag-handle-icon" svgIcon="icons:drag_handle"></mat-icon>
          <span class="ellipsis">{{ bible.bible.name }}</span>
        </p>
        <p *onlyDesktopTablet class="name" cdkDragHandle>{{ bible.bible.name }}</p>
        <p class="underline verse" (click)="openBottomSheet(bible)">{{ bible.verses[0].text }}</p>
      }
    </div>
  }
</div>
