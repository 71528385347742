<form [formGroup]="form">
  <div class="form-container desktop-and-portrait">
    <mat-accordion *onlyPortrait>
      <mat-expansion-panel #expansionPanel>
        <mat-expansion-panel-header>
          @if (books) {
            <mat-panel-title>
              {{ form.get('book').value | bookNameInBooks : books }}
              {{ form.get('chapter').value }}
              ({{ form.get('bible').value | uppercase }}@if (bibleCompare?.length === 0) {
              )
            }
              @if (bibleCompare?.length > 0) {
                / {{ bibleCompare[0] | uppercase }})
              }
            </mat-panel-title>
          }
        </mat-expansion-panel-header>

        <mat-form-field appearance="fill" class="field-bible">
          <mat-label>Biblia</mat-label>
          <mat-select formControlName="bible" [panelWidth]="null" (selectionChange)="bibleSelectionChange$.emit(form)">
            <mat-select-trigger *onlyPortrait>
              {{ form.get('bible').value | bibleName : bibleGroup }}
            </mat-select-trigger>

            @if (bibleGroup) {
              @if (bibleGroup[0]?.options.length > 0) {
                <mat-optgroup cdkDropList class="drop-list" (cdkDropListDropped)="drop($event, 0)">
                  {{ bibleGroup[0].name }}
                  <button>
                    <mat-icon *onlyPortrait svgIcon="icons:drag_handle"
                              (click)="disableDragDrop0 = !disableDragDrop0"></mat-icon>
                  </button>
                  @for (item of bibleGroup[0].options; track item.abbreviation) {
                    <mat-option [value]="item.abbreviation" class="box" cdkDrag
                                [class.disable-drag-drop]="disableDragDrop0">
                      @if (!disableDragDrop0) {
                        <mat-icon *onlyPortrait cdkDragHandle svgIcon="icons:drag_handle"></mat-icon>
                      }
                      <span class="name">{{ item.name }}</span>
                    </mat-option>
                  }
                </mat-optgroup>
              }
              @if (bibleGroup[1]?.options.length > 0) {
                <mat-optgroup cdkDropList class="drop-list" (cdkDropListDropped)="drop($event, 1)">
                  {{ bibleGroup[1].name }}
                  <button>
                    <mat-icon *onlyPortrait svgIcon="icons:drag_handle"
                              (click)="disableDragDrop1 = !disableDragDrop1"></mat-icon>
                  </button>
                  @for (item of bibleGroup[1].options; track item.abbreviation) {
                    <mat-option [value]="item.abbreviation" class="box" cdkDrag
                                [class.disable-drag-drop]="disableDragDrop1">
                      @if (!disableDragDrop1) {
                        <mat-icon *onlyPortrait cdkDragHandle svgIcon="icons:drag_handle"></mat-icon>
                      }
                      <span class="name">{{ item.name }}</span>
                    </mat-option>
                  }
                </mat-optgroup>
              }
              @if (bibleGroup[2]?.options.length > 0) {
                <mat-optgroup [label]="bibleGroup[2].name">
                  @for (item of bibleGroup[2].options; track item.abbreviation) {
                    <mat-option
                      [value]="item.abbreviation" class="drop-list-padding">
                      {{ item.name }}
                    </mat-option>
                  }
                </mat-optgroup>
              }
              @if (bibleGroup[3]?.options.length > 0) {
                <mat-optgroup [label]="bibleGroup[3].name">
                  @for (item of bibleGroup[3].options; track item.abbreviation) {
                    <mat-option
                      [value]="item.abbreviation" class="drop-list-padding">
                      {{ item.name }}
                    </mat-option>
                  }
                </mat-optgroup>
              }
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="field-book">
          <mat-label>Księga</mat-label>
          <mat-select formControlName="book" (selectionChange)="bookSelectionChange$.emit(form)">
            @for (group of bookGroup; track group.name; let j = $index) {
              <mat-optgroup [label]="group.name">
                @for (item of group.options; track item.abbreviation; let i = $index) {
                  <mat-option [value]="item.abbreviation"
                              [class.break]="i | bookBreak : j">
                    {{ item.name }}
                  </mat-option>
                }
              </mat-optgroup>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="field-chapter">
          <mat-label>Rozdział</mat-label>
          <mat-select formControlName="chapter" (selectionChange)="chapterSelectionChange$.emit(form)">
            @for (chapter of chapters; track chapter) {
              <mat-option [value]="chapter">{{ chapter }}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="field-bible-compare">
          <mat-label>Porównaj z</mat-label>
          <mat-select formControlName="bibleCompare" multiple [panelWidth]="null"
                      (selectionChange)="mobileSelectionChange($event)">
            <mat-select-trigger *onlyPortrait>
              {{ bibleCompare ? (bibleCompare[0] | bibleName : bibleGroup) : '' }}
            </mat-select-trigger>

            @if (bibleGroup) {
              @if (bibleGroup[0]?.options.length > 0) {
                <mat-optgroup cdkDropList class="drop-list" (cdkDropListDropped)="drop($event, 0)">
                  {{ bibleGroup[0].name }}
                  <button>
                    <mat-icon *onlyPortrait svgIcon="icons:drag_handle"
                              (click)="disableCompareDragDrop0 = !disableCompareDragDrop0"></mat-icon>
                  </button>
                  @for (item of bibleGroup[0].options; track item.abbreviation) {
                    <mat-option [value]="item.abbreviation"
                                class="box" cdkDrag [class.disable-drag-drop]="disableCompareDragDrop0"
                                [disabled]="(item.abbreviation === form.get('bible').value)
                              || !(form.get('book').value | isBibleAvailable : item.abbreviation)">
                      @if (!disableCompareDragDrop0) {
                        <mat-icon *onlyPortrait cdkDragHandle svgIcon="icons:drag_handle"></mat-icon>
                      }
                      <span class="name">{{ item.name }}</span>
                    </mat-option>
                  }
                </mat-optgroup>
              }
              @if (bibleGroup[1]?.options.length > 0) {
                <mat-optgroup [label]="bibleGroup[1].name" cdkDropList class="drop-list"
                              (cdkDropListDropped)="drop($event, 1)">
                  <button>
                    <mat-icon *onlyPortrait svgIcon="icons:drag_handle"
                              (click)="disableCompareDragDrop1 = !disableCompareDragDrop1"></mat-icon>
                  </button>
                  @for (item of bibleGroup[1].options; track item.abbreviation) {
                    <mat-option [value]="item.abbreviation"
                                class="box" cdkDrag [class.disable-drag-drop]="disableCompareDragDrop0"
                                [disabled]="(item.abbreviation === form.get('bible').value)
                              || !(form.get('book').value | isBibleAvailable : item.abbreviation)">
                      @if (!disableCompareDragDrop1) {
                        <mat-icon *onlyPortrait cdkDragHandle svgIcon="icons:drag_handle"></mat-icon>
                      }
                      <span class="name">{{ item.name }}</span>
                    </mat-option>
                  }
                </mat-optgroup>
              }
              @if (bibleGroup[2]?.options.length > 0) {
                <mat-optgroup [label]="bibleGroup[2].name">
                  @for (item of bibleGroup[2].options; track item.abbreviation) {
                    <mat-option
                      [disabled]="(item.abbreviation === form.get('bible').value)
                              || !(form.get('book').value | isBibleAvailable : item.abbreviation)"
                      [value]="item.abbreviation" class="drop-list-padding">
                      {{ item.name }}
                    </mat-option>
                  }
                </mat-optgroup>
              }
              @if (bibleGroup[3]?.options.length > 0) {
                <mat-optgroup [label]="bibleGroup[3].name">
                  @for (item of bibleGroup[3].options; track item.abbreviation) {
                    <mat-option
                      [disabled]="(item.abbreviation === form.get('bible').value)
                              || !(form.get('book').value | isBibleAvailable : item.abbreviation)"
                      [value]="item.abbreviation" class="drop-list-padding">
                      {{ item.name }}
                    </mat-option>
                  }
                </mat-optgroup>
              }
            }
          </mat-select>
        </mat-form-field>

        <app-bible-filter-actions
          [hideVerseInNewLine]="bibleCompare.length > 0"
          (copyVerses$)="copyVerses$.emit()"
          (openSelectTranslationsDialog$)="openSelectTranslationsDialog$.emit()"
          (changeFontSize$)="changeFontSize$.emit($event)"
          (versesInNewLine$)="versesInNewLine$.emit($event)"
        ></app-bible-filter-actions>
      </mat-expansion-panel>
    </mat-accordion>

    <ng-container *onlyDesktop>
      <mat-form-field appearance="fill" class="field-bible">
        <mat-label>Biblia</mat-label>
        <mat-select formControlName="bible" [panelWidth]="null" (selectionChange)="bibleSelectionChange$.emit(form)">
          <mat-select-trigger *onlyPortrait>
            {{ form.get('bible').value | bibleName : bibleGroup }}
          </mat-select-trigger>

          @if (bibleGroup) {
            @if (bibleGroup[0]?.options.length > 0) {
              <mat-optgroup cdkDropList class="drop-list" (cdkDropListDropped)="drop($event, 0)">
                {{ bibleGroup[0].name }}
                <button>
                  <mat-icon *onlyPortrait svgIcon="icons:drag_handle"
                            (click)="disableDragDrop0 = !disableDragDrop0"></mat-icon>
                </button>
                @for (item of bibleGroup[0].options; track item.abbreviation) {
                  <mat-option [value]="item.abbreviation" class="box" cdkDrag
                              [class.disable-drag-drop]="disableDragDrop0">
                    @if (!disableDragDrop0) {
                      <mat-icon *onlyPortrait cdkDragHandle svgIcon="icons:drag_handle"></mat-icon>
                    }
                    <span class="name">{{ item.name }}</span>
                  </mat-option>
                }
              </mat-optgroup>
            }
            @if (bibleGroup[1]?.options.length > 0) {
              <mat-optgroup cdkDropList class="drop-list" (cdkDropListDropped)="drop($event, 1)">
                {{ bibleGroup[1].name }}
                <button>
                  <mat-icon *onlyPortrait svgIcon="icons:drag_handle"
                            (click)="disableDragDrop1 = !disableDragDrop1"></mat-icon>
                </button>
                @for (item of bibleGroup[1].options; track item.abbreviation) {
                  <mat-option [value]="item.abbreviation" class="box" cdkDrag
                              [class.disable-drag-drop]="disableDragDrop1">
                    @if (!disableDragDrop1) {
                      <mat-icon *onlyPortrait cdkDragHandle svgIcon="icons:drag_handle"></mat-icon>
                    }
                    <span class="name">{{ item.name }}</span>
                  </mat-option>
                }
              </mat-optgroup>
            }
            @if (bibleGroup[2]?.options.length > 0) {
              <mat-optgroup [label]="bibleGroup[2].name">
                @for (item of bibleGroup[2].options; track item.abbreviation) {
                  <mat-option
                    [value]="item.abbreviation" class="drop-list-padding">
                    {{ item.name }}
                  </mat-option>
                }
              </mat-optgroup>
            }
            @if (bibleGroup[3]?.options.length > 0) {
              <mat-optgroup [label]="bibleGroup[3].name">
                @for (item of bibleGroup[3].options; track item.abbreviation) {
                  <mat-option
                    [value]="item.abbreviation" class="drop-list-padding">
                    {{ item.name }}
                  </mat-option>
                }
              </mat-optgroup>
            }
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="field-book">
        <mat-label>Księga</mat-label>
        <mat-select formControlName="book" (selectionChange)="bookSelectionChange$.emit(form)">
          @for (group of bookGroup; track group.name; let j = $index) {
            <mat-optgroup [label]="group.name">
              @for (item of group.options; track item.abbreviation; let i = $index) {
                <mat-option [value]="item.abbreviation"
                            [class.break]="i | bookBreak : j">
                  {{ item.name }}
                </mat-option>
              }
            </mat-optgroup>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="field-chapter">
        <mat-label>Rozdział</mat-label>
        <mat-select formControlName="chapter" (selectionChange)="chapterSelectionChange$.emit(form)">
          @for (chapter of chapters; track chapter) {
            <mat-option [value]="chapter">{{ chapter }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="field-bible-compare">
        <mat-label>Porównaj z</mat-label>
        <mat-select formControlName="bibleCompare" multiple [panelWidth]="null"
                    (selectionChange)="bibleCompareSelectionChange$.emit(form)">
          <mat-select-trigger *onlyPortrait>
            {{ bibleCompare ? (bibleCompare[0] | bibleName : bibleGroup) : '' }}
          </mat-select-trigger>

          @if (bibleGroup) {
            @if (bibleGroup[0]?.options.length > 0) {
              <mat-optgroup cdkDropList class="drop-list" (cdkDropListDropped)="drop($event, 0)">
                {{ bibleGroup[0].name }}
                <button *onlyPortrait>
                  <mat-icon svgIcon="icons:drag_handle"
                            (click)="disableCompareDragDrop0 = !disableCompareDragDrop0"></mat-icon>
                </button>
                @for (item of bibleGroup[0].options; track item.abbreviation) {
                  <mat-option
                    [value]="item.abbreviation" class="box" cdkDrag
                    [disabled]="item.abbreviation === form.get('bible').value
                              || (bibleCompare | overLimit : item.abbreviation : maxCompareColumns)
                              || !(form.get('book').value | isBibleAvailable : item.abbreviation)"
                    [class.disable-drag-drop]="disableCompareDragDrop0">
                    @if (!disableCompareDragDrop0) {
                      <mat-icon *onlyPortrait cdkDragHandle svgIcon="icons:drag_handle"></mat-icon>
                    }
                    <span class="name">{{ item.name }}</span>
                  </mat-option>
                }
              </mat-optgroup>
            }
            @if (bibleGroup[1]?.options.length > 0) {
              <mat-optgroup [label]="bibleGroup[1].name" cdkDropList class="drop-list"
                            (cdkDropListDropped)="drop($event, 1)">
                <button>
                  <mat-icon *onlyPortrait svgIcon="icons:drag_handle"
                            (click)="disableCompareDragDrop1 = !disableCompareDragDrop1"></mat-icon>
                </button>
                @for (item of bibleGroup[1].options; track item.abbreviation) {
                  <mat-option
                    [value]="item.abbreviation" class="box" cdkDrag
                    [disabled]="item.abbreviation === form.get('bible').value
                              || (bibleCompare | overLimit : item.abbreviation : maxCompareColumns)
                              || !(form.get('book').value | isBibleAvailable : item.abbreviation)"
                    [class.disable-drag-drop]="disableCompareDragDrop0">
                    @if (!disableCompareDragDrop1) {
                      <mat-icon *onlyPortrait cdkDragHandle svgIcon="icons:drag_handle"></mat-icon>
                    }
                    <span class="name">{{ item.name }}</span>
                  </mat-option>
                }
              </mat-optgroup>
            }
            @if (bibleGroup[2]?.options.length > 0) {
              <mat-optgroup [label]="bibleGroup[2].name">
                @for (item of bibleGroup[2].options; track item.abbreviation) {
                  <mat-option
                    [disabled]="item.abbreviation === form.get('bible').value
                              || (bibleCompare | overLimit : item.abbreviation : maxCompareColumns)
                              || !(form.get('book').value | isBibleAvailable : item.abbreviation)"
                    [value]="item.abbreviation" class="drop-list-padding">
                    {{ item.name }}
                  </mat-option>
                }
              </mat-optgroup>
            }
            @if (bibleGroup[3]?.options.length > 0) {
              <mat-optgroup [label]="bibleGroup[3].name">
                @for (item of bibleGroup[3].options; track item.abbreviation) {
                  <mat-option
                    [disabled]="item.abbreviation === form.get('bible').value
                              || (bibleCompare | overLimit : item.abbreviation : maxCompareColumns)
                              || !(form.get('book').value | isBibleAvailable : item.abbreviation)"
                    [value]="item.abbreviation" class="drop-list-padding">
                    {{ item.name }}
                  </mat-option>
                }
              </mat-optgroup>
            }
          }
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>

  <div class="form-container desktop-small-and-tablet">
    <div class="flex-container">
      <mat-form-field appearance="fill" class="field-bible">
        <mat-label>Biblia</mat-label>
        <mat-select formControlName="bible" [panelWidth]="null" (selectionChange)="bibleSelectionChange$.emit(form)">
          <mat-select-trigger *onlyTablet>
            {{ form.get('bible').value | bibleName : bibleGroup }}
          </mat-select-trigger>

          @if (bibleGroup) {
            @if (bibleGroup[0]?.options.length > 0) {
              <mat-optgroup cdkDropList class="drop-list" (cdkDropListDropped)="drop($event, 0)">
                {{ bibleGroup[0].name }}
                <button *onlyTablet>
                  <mat-icon svgIcon="icons:drag_handle" (click)="disableDragDrop0 = !disableDragDrop0"></mat-icon>
                </button>
                @for (item of bibleGroup[0].options; track item.abbreviation) {
                  <mat-option [value]="item.abbreviation"
                              class="box" cdkDrag [class.disable-drag-drop]="disableDragDrop0">
                    @if (!disableDragDrop0) {
                      <mat-icon *onlyTablet cdkDragHandle svgIcon="icons:drag_handle"></mat-icon>
                    }
                    <span class="name">{{ item.name }}</span>
                  </mat-option>
                }
              </mat-optgroup>
            }
            @if (bibleGroup[1]?.options.length > 0) {
              <mat-optgroup cdkDropList class="drop-list" (cdkDropListDropped)="drop($event, 1)">
                {{ bibleGroup[1].name }}
                <button *onlyTablet>
                  <mat-icon svgIcon="icons:drag_handle" (click)="disableDragDrop1 = !disableDragDrop1"></mat-icon>
                </button>
                @for (item of bibleGroup[1].options; track item.abbreviation) {
                  <mat-option [value]="item.abbreviation"
                              class="box" cdkDrag [class.disable-drag-drop]="disableDragDrop1">
                    @if (!disableDragDrop1) {
                      <mat-icon *onlyTablet cdkDragHandle svgIcon="icons:drag_handle"></mat-icon>
                    }
                    <span class="name">{{ item.name }}</span>
                  </mat-option>
                }
              </mat-optgroup>
            }
            @if (bibleGroup[2]?.options.length > 0) {
              <mat-optgroup [label]="bibleGroup[2].name">
                @for (item of bibleGroup[2].options; track item.abbreviation) {
                  <mat-option [value]="item.abbreviation">
                    {{ item.name }}
                  </mat-option>
                }
              </mat-optgroup>
            }
            @if (bibleGroup[3]?.options.length > 0) {
              <mat-optgroup [label]="bibleGroup[3].name">
                @for (item of bibleGroup[3].options; track item.abbreviation) {
                  <mat-option [value]="item.abbreviation">
                    {{ item.name }}
                  </mat-option>
                }
              </mat-optgroup>
            }
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="field-book">
        <mat-label>Księga</mat-label>
        <mat-select formControlName="book" (selectionChange)="bookSelectionChange$.emit(form)">
          @for (group of bookGroup; track group.name; let j = $index) {
            <mat-optgroup [label]="group.name">
              @for (item of group.options; track item.abbreviation; let i = $index) {
                <mat-option [value]="item.abbreviation"
                            [class.break]="i | bookBreak : j">
                  {{ item.name }}
                </mat-option>
              }
            </mat-optgroup>
          }
        </mat-select>
      </mat-form-field>
    </div>

    <div class="flex-container">
      <mat-form-field appearance="fill" class="field-chapter">
        <mat-label>Rozdział</mat-label>
        <mat-select formControlName="chapter" (selectionChange)="chapterSelectionChange$.emit(form)">
          @for (chapter of chapters; track chapter) {
            <mat-option [value]="chapter">{{ chapter }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="field-bible-compare">
        <mat-label>Porównaj z</mat-label>
        <mat-select formControlName="bibleCompare" multiple [panelWidth]="null"
                    (selectionChange)="bibleCompareSelectionChange$.emit(form)">
          <mat-select-trigger *onlyTablet>
            {{ bibleCompare ? (bibleCompare[0] | bibleName : bibleGroup) : '' }}
          </mat-select-trigger>

          @if (bibleGroup) {
            @if (bibleGroup[0]?.options.length > 0) {
              <mat-optgroup cdkDropList class="drop-list" (cdkDropListDropped)="drop($event, 0)">
                {{ bibleGroup[0].name }}
                <button *onlyTablet>
                  <mat-icon svgIcon="icons:drag_handle"
                            (click)="disableCompareDragDrop0 = !disableCompareDragDrop0"></mat-icon>
                </button>
                @for (item of bibleGroup[0].options; track item.abbreviation) {
                  <mat-option [value]="item.abbreviation"
                              class="box" cdkDrag [class.disable-drag-drop]="disableCompareDragDrop0"
                              [disabled]="(item.abbreviation === form.get('bible').value)
                            || !(form.get('book').value | isBibleAvailable : item.abbreviation)
                            || (item.abbreviation !== form.get('bible').value
                            && item.abbreviation !== form.get('bibleCompare').value[0]
                            && bibleCompare?.length > 0)">
                    @if (!disableCompareDragDrop0) {
                      <mat-icon *onlyTablet cdkDragHandle svgIcon="icons:drag_handle"></mat-icon>
                    }
                    <span class="name">{{ item.name }}</span>
                  </mat-option>
                }
              </mat-optgroup>
            }
            @if (bibleGroup[1]?.options.length > 0) {
              <mat-optgroup [label]="bibleGroup[1].name" cdkDropList class="drop-list"
                            (cdkDropListDropped)="drop($event, 1)">
                <button *onlyTablet>
                  <mat-icon svgIcon="icons:drag_handle"
                            (click)="disableCompareDragDrop1 = !disableCompareDragDrop1"></mat-icon>
                </button>
                @for (item of bibleGroup[1].options; track item.abbreviation) {
                  <mat-option [value]="item.abbreviation"
                              class="box" cdkDrag [class.disable-drag-drop]="disableCompareDragDrop1"
                              [disabled]="(item.abbreviation === form.get('bible').value)
                            || !(form.get('book').value | isBibleAvailable : item.abbreviation)
                            || (item.abbreviation !== form.get('bible').value
                            && item.abbreviation !== form.get('bibleCompare').value[0]
                            && bibleCompare?.length > 0)">
                    @if (!disableCompareDragDrop1) {
                      <mat-icon *onlyTablet cdkDragHandle svgIcon="icons:drag_handle"></mat-icon>
                    }
                    <span class="name">{{ item.name }}</span>
                  </mat-option>
                }
              </mat-optgroup>
            }

            @if (bibleGroup[2]?.options.length > 0) {
              <mat-optgroup [label]="bibleGroup[2].name">
                @for (item of bibleGroup[2].options; track item.abbreviation) {
                  <mat-option
                    [value]="item.abbreviation"
                    [disabled]="(item.abbreviation === form.get('bible').value)
                            || !(form.get('book').value | isBibleAvailable : item.abbreviation)
                            || (item.abbreviation !== form.get('bible').value
                            && item.abbreviation !== form.get('bibleCompare').value[0]
                            && bibleCompare?.length > 0)">
                    {{ item.name }}
                  </mat-option>
                }
              </mat-optgroup>
            }

            @if (bibleGroup[3]?.options.length > 0) {
              <mat-optgroup [label]="bibleGroup[3].name">
                @for (item of bibleGroup[3].options; track item.abbreviation) {
                  <mat-option
                    [value]="item.abbreviation"
                    [disabled]="(item.abbreviation === form.get('bible').value)
                            || !(form.get('book').value | isBibleAvailable : item.abbreviation)
                            || (item.abbreviation !== form.get('bible').value
                            && item.abbreviation !== form.get('bibleCompare').value[0]
                            && bibleCompare?.length > 0)">
                    {{ item.name }}
                  </mat-option>
                }
              </mat-optgroup>
            }

          }
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="form-container landscape" *onlyLandscape>
    <mat-form-field appearance="fill" class="field-bible">
      <mat-label>Biblia</mat-label>
      <mat-select formControlName="bible" [panelWidth]="null" (selectionChange)="bibleSelectionChange$.emit(form)">
        <mat-select-trigger>
          {{ form.get('bible').value | bibleName : bibleGroup }}
        </mat-select-trigger>

        @if (bibleGroup) {

          @if (bibleGroup[0]?.options.length > 0) {
            <mat-optgroup cdkDropList class="drop-list" (cdkDropListDropped)="drop($event, 0)">
              {{ bibleGroup[0].name }}

              <button>
                <mat-icon svgIcon="icons:drag_handle" (click)="disableDragDrop0 = !disableDragDrop0"></mat-icon>
              </button>

              @for (item of bibleGroup[0].options; track item.abbreviation) {
                <mat-option [value]="item.abbreviation"
                            class="box" cdkDrag [class.disable-drag-drop]="disableDragDrop0">
                  @if (!disableDragDrop0) {
                    <mat-icon cdkDragHandle svgIcon="icons:drag_handle"></mat-icon>
                  }
                  <span class="name">{{ item.name }}</span>
                </mat-option>
              }
            </mat-optgroup>
          }
          @if (bibleGroup[1]?.options.length > 0) {
            <mat-optgroup cdkDropList class="drop-list" (cdkDropListDropped)="drop($event, 1)">
              {{ bibleGroup[1].name }}
              <button>
                <mat-icon svgIcon="icons:drag_handle" (click)="disableDragDrop1 = !disableDragDrop1"></mat-icon>
              </button>
              @for (item of bibleGroup[1].options; track item.abbreviation) {
                <mat-option [value]="item.abbreviation"
                            class="box" cdkDrag [class.disable-drag-drop]="disableDragDrop1">
                  @if (!disableDragDrop1) {
                    <mat-icon cdkDragHandle svgIcon="icons:drag_handle"></mat-icon>
                  }
                  <span class="name">{{ item.name }}</span>
                </mat-option>
              }
            </mat-optgroup>
          }
          @if (bibleGroup[2]?.options.length > 0) {
            <mat-optgroup [label]="bibleGroup[2].name">
              @for (item of bibleGroup[2].options; track item.abbreviation) {
                <mat-option
                  [value]="item.abbreviation" class="drop-list-padding">
                  {{ item.name }}
                </mat-option>
              }
            </mat-optgroup>
          }
          @if (bibleGroup[3]?.options.length > 0) {
            <mat-optgroup [label]="bibleGroup[3].name">
              @for (item of bibleGroup[3].options; track item.abbreviation) {
                <mat-option
                  [value]="item.abbreviation" class="drop-list-padding">
                  {{ item.name }}
                </mat-option>
              }
            </mat-optgroup>
          }
        }
      </mat-select>
    </mat-form-field>

    <div class="flex-container">
      <mat-form-field appearance="fill" class="field-book">
        <mat-label>Księga</mat-label>
        <mat-select formControlName="book" (selectionChange)="bookSelectionChange$.emit(form)">
          @for (group of bookGroup; track group.name; let j = $index) {
            <mat-optgroup [label]="group.name">
              @for (item of group.options; track item.abbreviation; let i = $index) {
                <mat-option [value]="item.abbreviation" class="test"
                            [class.break]="i | bookBreak : j">
                  {{ item.name }}
                </mat-option>
              }
            </mat-optgroup>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="field-chapter">
        <mat-label>Rozdział</mat-label>
        <mat-select formControlName="chapter" (selectionChange)="chapterSelectionChange$.emit(form)">
          @for (chapter of chapters; track chapter) {
            <mat-option [value]="chapter">{{ chapter }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field appearance="fill" class="field-bible-compare">
      <mat-label>Porównaj z</mat-label>
      <mat-select formControlName="bibleCompare" multiple [panelWidth]="null"
                  (selectionChange)="mobileSelectionChange($event)">
        <mat-select-trigger>
          {{ bibleCompare ? (bibleCompare[0] | bibleName : bibleGroup) : '' }}
        </mat-select-trigger>

        @if (bibleGroup) {
          @if (bibleGroup[0]?.options.length > 0) {
            <mat-optgroup cdkDropList class="drop-list" (cdkDropListDropped)="drop($event, 0)">
              {{ bibleGroup[0].name }}
              <button>
                <mat-icon svgIcon="icons:drag_handle"
                          (click)="disableCompareDragDrop0 = !disableCompareDragDrop0"></mat-icon>
              </button>
              @for (item of bibleGroup[0].options; track item.abbreviation) {
                <mat-option [value]="item.abbreviation"
                            class="box" cdkDrag [class.disable-drag-drop]="disableCompareDragDrop0"
                            [disabled]="(item.abbreviation === form.get('bible').value)
                          || !(form.get('book').value | isBibleAvailable : item.abbreviation)">
                  @if (!disableCompareDragDrop0) {
                    <mat-icon cdkDragHandle svgIcon="icons:drag_handle"></mat-icon>
                  }
                  <span class="name">{{ item.name }}</span>
                </mat-option>
              }
            </mat-optgroup>
          }
          @if (bibleGroup[1]?.options.length > 0) {
            <mat-optgroup cdkDropList class="drop-list" (cdkDropListDropped)="drop($event, 1)">
              {{ bibleGroup[1].name }}
              <button>
                <mat-icon svgIcon="icons:drag_handle"
                          (click)="disableCompareDragDrop1 = !disableCompareDragDrop1"></mat-icon>
              </button>
              @for (item of bibleGroup[1].options; track item.abbreviation) {
                <mat-option [value]="item.abbreviation"
                            class="box" cdkDrag [class.disable-drag-drop]="disableCompareDragDrop1"
                            [disabled]="(item.abbreviation === form.get('bible').value)
                          || !(form.get('book').value | isBibleAvailable : item.abbreviation)">
                  @if (!disableCompareDragDrop1) {
                    <mat-icon cdkDragHandle svgIcon="icons:drag_handle"></mat-icon>
                  }
                  <span class="name">{{ item.name }}</span>
                </mat-option>
              }
            </mat-optgroup>
          }
          @if (bibleGroup[2]?.options.length > 0) {
            <mat-optgroup [label]="bibleGroup[2].name">
              @for (item of bibleGroup[2].options; track item.abbreviation) {
                <mat-option
                  [disabled]="(item.abbreviation === form.get('bible').value)
                          || !(form.get('book').value | isBibleAvailable : item.abbreviation)"
                  [value]="item.abbreviation" class="drop-list-padding">
                  {{ item.name }}
                </mat-option>
              }
            </mat-optgroup>
          }
          @if (bibleGroup[3]?.options.length > 0) {
            <mat-optgroup [label]="bibleGroup[3].name">
              @for (item of bibleGroup[3].options; track item.abbreviation) {
                <mat-option
                  [disabled]="(item.abbreviation === form.get('bible').value)
                          || !(form.get('book').value | isBibleAvailable : item.abbreviation)"
                  [value]="item.abbreviation" class="drop-list-padding">
                  {{ item.name }}
                </mat-option>
              }
            </mat-optgroup>
          }
        }
      </mat-select>
    </mat-form-field>
  </div>
</form>
