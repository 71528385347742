import { BibliaInfoBook } from './biblia-info/biblia-info-book';

export const BlueLetterBible: Map<BibliaInfoBook, string> = new Map([
  [BibliaInfoBook.RODZAJU, 'gen'],
  [BibliaInfoBook.WYJSCIA, 'exo'],
  [BibliaInfoBook.KAPLANSKA, 'lev'],
  [BibliaInfoBook.LICZB, 'num'],
  [BibliaInfoBook.POWTORZONEGO_PRAWA, 'deu'],
  [BibliaInfoBook.JOZUEGO, 'jos'],
  [BibliaInfoBook.SEDZIOW, 'jdg'],
  [BibliaInfoBook.RUT, 'rth'],
  [BibliaInfoBook.PIERWSZA_SAMUELA, '1sa'],
  [BibliaInfoBook.DRUGA_SAMUELA, '2sa'],
  [BibliaInfoBook.PIERWSZA_KROLEWSKA, '1ki'],
  [BibliaInfoBook.DRUGA_KROLEWSKA, '2ki'],
  [BibliaInfoBook.PIERWSZA_KRONIK, '1ch'],
  [BibliaInfoBook.DRUGA_KRONIK, '2ch'],
  [BibliaInfoBook.EZDRASZA, 'ezr'],
  [BibliaInfoBook.NEHEMIASZA, 'neh'],
  [BibliaInfoBook.ESTERY, 'est'],
  [BibliaInfoBook.HIOBA, 'job'],
  [BibliaInfoBook.PSALMY, 'psa'],
  [BibliaInfoBook.PRZYPOWIESCI, 'pro'],
  [BibliaInfoBook.KAZNODZIEI, 'ecc'],
  [BibliaInfoBook.PIESNI_NAD_PIESNIAMI, 'sng'],
  [BibliaInfoBook.IZAJASZA, 'isa'],
  [BibliaInfoBook.JEREMIASZA, 'jer'],
  [BibliaInfoBook.LAMENTACJI, 'lam'],
  [BibliaInfoBook.EZECHIELA, 'eze'],
  [BibliaInfoBook.DANIELA, 'dan'],
  [BibliaInfoBook.OZEASZA, 'hos'],
  [BibliaInfoBook.JOELA, 'joe'],
  [BibliaInfoBook.AMOSA, 'amo'],
  [BibliaInfoBook.ABDIASZA, 'oba'],
  [BibliaInfoBook.JONASZA, 'jon'],
  [BibliaInfoBook.MICHEASZA, 'mic'],
  [BibliaInfoBook.NAHUMA, 'nah'],
  [BibliaInfoBook.HABAKUKA, 'hab'],
  [BibliaInfoBook.SOFONIASZA, 'zep'],
  [BibliaInfoBook.AGGEUSZA, 'hag'],
  [BibliaInfoBook.ZACHARIASZA, 'zec'],
  [BibliaInfoBook.MALACHIASZA, 'mal'],
  [BibliaInfoBook.EWANGELIA_MATEUSZA, 'mat'],
  [BibliaInfoBook.EWANGELIA_MARKA, 'mar'],
  [BibliaInfoBook.EWANGELIA_LUKASZA, 'luk'],
  [BibliaInfoBook.EWANGELIA_JANA, 'jhn'],
  [BibliaInfoBook.DZIEJE_APOSTOLSKIE, 'act'],
  [BibliaInfoBook.LIST_DO_RZYMIAN, 'rom'],
  [BibliaInfoBook.PIERWSZY_LIST_DO_KORYNTIAN, '1co'],
  [BibliaInfoBook.DRUGI_LIST_DO_KORYNTIAN, '2co'],
  [BibliaInfoBook.LIST_DO_GALACJAN, 'gal'],
  [BibliaInfoBook.LIST_DO_EFEZJAN, 'eph'],
  [BibliaInfoBook.LIST_DO_FILIPIAN, 'phl'],
  [BibliaInfoBook.LIST_DO_KOLOSAN, 'col'],
  [BibliaInfoBook.PIERWSZY_LIST_DO_TESALONICZAN, '1th'],
  [BibliaInfoBook.DRUGI_LIST_DO_TESALONICZAN, '2th'],
  [BibliaInfoBook.PIERWSZY_LIST_DO_TYMOTEUSZA, '1ti'],
  [BibliaInfoBook.DRUGI_LIST_DO_TYMOTEUSZA, '2ti'],
  [BibliaInfoBook.LIST_DO_TYTUSA, 'tit'],
  [BibliaInfoBook.LIST_DO_FILEMONA, 'phm'],
  [BibliaInfoBook.LIST_DO_HEBRAJCZYKOW, 'heb'],
  [BibliaInfoBook.LIST_JAKUBA, 'jas'],
  [BibliaInfoBook.PIERWSZY_LIST_PIOTRA, '1pe'],
  [BibliaInfoBook.DRUGI_LIST_PIOTRA, '2pe'],
  [BibliaInfoBook.PIERWSZY_LIST_JANA, '1jo'],
  [BibliaInfoBook.DRUGI_LIST_JANA, '2jo'],
  [BibliaInfoBook.TRZECI_LIST_JANA, '3jo'],
  [BibliaInfoBook.LIST_JUDY, 'jde'],
  [BibliaInfoBook.OBJAWIENIE_JANA, 'rev'],
]);
