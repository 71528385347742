<div class="book-container">
  <div class="title">
    {{ data.item.book.abbreviation | abbreviation }}. {{ data.item.chapter || 1 }},{{ data.verseNo }}
  </div>

  <div class="actions">
    @if (data.openChapterOption) {
      <button (click)="openChapter()">
        <mat-icon svgIcon="icons:import_contacts"></mat-icon>otwórz rozdział
      </button>
    }

    <button (click)="compareVerse()">
      <mat-icon svgIcon="icons:compare"></mat-icon>porównaj w przekładach
    </button>

    @if (strongUrl) {
      <a *onlyDesktop (click)="close($event)" (auxclick)="close($event)">
        <mat-icon svgIcon="icons:ubg"></mat-icon>przejdź do systemu Stronga
      </a>
      <a *onlyPortraitLandscapeTablet (click)="bottomSheetRef.dismiss()" [routerLink]="strongUrl" [queryParams]="queryParams" [fragment]="fragment">
        <mat-icon svgIcon="icons:ubg"></mat-icon>przejdź do systemu Stronga
      </a>
    }

    @if (data.isBlueLetterBibleAvailable) {
      <a [href]="getBlueLetterLink()">
        <mat-icon svgIcon="icons:open_in_new" matTooltip="nowa karta" matTooltipPosition="above"></mat-icon>przekład interlinearny
      </a>
    }

    <button (click)="selectVerse()">
      <mat-icon svgIcon="icons:content_copy"></mat-icon>kopiuj werset
    </button>

    @if (!data.isInStorage) {
      <button (click)="addVerseToStorage()">
        <mat-icon svgIcon="icons:add_circle_outline"></mat-icon>dodaj do ulubionych
      </button>
    }

    @if (data.isInStorage) {
      <button (click)="removeVerseFromStorage()">
        <mat-icon svgIcon="icons:delete"></mat-icon>usuń z ulubionych
      </button>
    }
  </div>
</div>
